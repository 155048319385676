import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
// import { useParams } from "react-router-dom";
import { RootState } from "../../../store/root-reducer";
import { DeleteWishlistAPI, PostWishlistAPI, wishlistAPI } from "../../../store/screens/wishlist";

export const useWishlist = () => {
  const dispatch = useDispatch();
  const wishlist = useSelector((state: RootState) => state.wishlists);
  const [wishlists, setWishlists] = useState([]);

  useEffect(() => {
    let user = localStorage.getItem("user");
    let users = user ? JSON.parse(user) : null;
    let params = {
      user: users,
    };
    dispatch(wishlistAPI(params));
  }, []);

  useEffect(()=>{
    setWishlists(wishlist.wishlist);
  },[wishlist])

  return{
    wishlists,
  }
}

  //////////////////////////////////////

  export const useWishlistHandle=()=>{
    const dispatch = useDispatch();
    const wishlistss = useSelector((state: RootState) => state.wishlists).wishlist;
    const [wishlistData, setWishlistData] = useState([]);
    const [isRevaled, setIsRevaled] = useState("");
    const [show1, setShow1]= useState(false);
    const [show2, setShow2]= useState(false);
    const hideshow = (name:any) => {
      setIsRevaled(name);
    };

    useEffect(()=>{
      let wishlistArray:any = {...wishlistData};
      wishlistss && wishlistss.map((wishlist :any)=>{
        wishlistArray[wishlist.item_code] = true;
      });
      setWishlistData(wishlistArray);
    },[wishlistss])

  const handleWhishlist = async (name: any, isAdded : any) => {
    let wishlist:any = {...wishlistData};
    wishlist[name] = isAdded;
    let user = localStorage.getItem('user');
    let params = {
      item_code : name,
      user : user && JSON.parse(user)
    }
    if (isAdded) {
      await dispatch(PostWishlistAPI(params));
      setShow1(true)
    } else {
      await dispatch(DeleteWishlistAPI(params));
      setShow2(true)
    }
    let users = user ? JSON.parse(user) : null;
    let getparams = {
      user: users,
    };
    dispatch(wishlistAPI(getparams));
    setWishlistData(wishlist);
    // console.log(name);
  };

  return {
    handleWhishlist,
    wishlistData,
    isRevaled,

    hideshow,

    show1,
    setShow1,
    show2,
    setShow2
  };
};

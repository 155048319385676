import axios from "axios";
import { DEFAULT_API_CONFIG } from "../config/api-config";

const AddReviewAPI = async (
  order_id:string,
  product_code:string,
  customer_name:string,
  order_date:string,
  review_input: any) => {
  let response: any;

  const params = `?order_id=${order_id}&customer_name=${customer_name}&order_date=${order_date}&product_code=${product_code}&review=${review_input}`;

  let login = localStorage.getItem("token");
  const url = `${DEFAULT_API_CONFIG?.url}/api/method/digitalcatalog_api_erpnext.api.sales_order_review.add_sales_order_review`;

  const config = {
    headers:{
      Authorization: login && JSON.parse(login)?.access_token,
    }
  }

  await axios.post(`${url}${params}`, undefined,config).then((res:any)=>
  {
    console.log("add review api success",res);
    response = res;
  }).catch((err:any)=>
  {
    console.log("add review api err",err);
    response = err;
  })

  return response;
};

export default AddReviewAPI;

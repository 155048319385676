import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { productdetailAPI } from "../../store/screens/products";
import { RootState } from "../../store/root-reducer";
import { CONSTANT } from "../../utils/constant";
import noimage from "../../assets/img/nia.jpg";
import AddFieldForm from "../../components/addFieldForm";
import {
  useWishlist,
  useWishlistHandle,
} from "../whishlist/components/wishlist-hooks";
import Purity from "../../components/purity";
import { Modal } from "react-bootstrap";
import { useCart } from "../cart/components/cart-hooks";
import { useProducts } from "../products/components/product-hooks";
import Breadcrumb from "../../components/breadcrumb";
import { useProductDetail } from "./components/product-detail-hooks";
// import "../../assets/css/newdetail.css";
import ToastNotification from "../../components/toast-notification";
import "../../assets/css/product-detail-page.css";
import { Norecord } from "../../components/norecord";
import img from "../../assets/img/search_not_found.png";
import axios from "axios";
import ProductImage from "./ProductImage";
import { DEFAULT_API_CONFIG } from "../../services/config/api-config";

const ProductDetail = () => {
  const productData = useSelector((state: RootState) => state.products);
  let user: any = localStorage.getItem("user");
  let users = user ? JSON.parse(user) : null;
  const params = useParams();
  const productID: any = params.productId;

  const {
    arrayOfCodes,
    handleSortBySeq,
    savePrevAndNextData,
    sortBySeq,
    handlesortByDesc,
    sortByDesc,
  } = useProducts();
  const param = useParams();
  const [isvariant, setisvariant] = useState<string>("");
  let [prodvariants, setprodvariants] = useState<any>([]);
  const [colorValue, setcolorValue] = useState("Yellow");
  let [variantWeight, setVariantWeight] = useState(param.produ);

  const { handleWhishlist, wishlistData, show1, setShow1, show2, setShow2 } =
    useWishlistHandle();
  const { productdetail, productPrevNextData } = useProductDetail();

  const currentObj = productPrevNextData.find((obj: any) =>
    obj.hasOwnProperty(productID)
  );
  const prevValue = currentObj ? currentObj[productID].prev : null;
  const nextValue = currentObj ? currentObj[productID].next : null;

  const [shows, setShows] = useState(false);
  const handleImage = (e: any) => {
    console.log("hpojposjci");
    e.preventDefault();
    setShows(true);
  };

  useEffect(() => {
    if (param.productId?.split("-").length !== undefined) {
      if (param.productId?.split("-").length > 2) {
        setVariantWeight(param.productId?.split("-")[2]);
      } else {
        setVariantWeight(param.productId?.split("-")[1]);
      }
    }
  }, []);

  useEffect(() => {
    if (productdetail.variant_of) {
      const login = localStorage.getItem("token");
      const config = {
        headers: {
          Authorization: login && JSON.parse(login)?.access_token,
        },
      };
      const getVariants = async (variant: any) => {
        console.log(variant);
        await axios
          .post(
            `${DEFAULT_API_CONFIG.url}/api/method/digitalcatalog_api_erpnext.api.product_detail.get_product_detail`,
            { item_code: `${variant}`, fields: "*", user: users },
            config
          )
          .then((res) => {
            console.log("product variants api res", res);
            setprodvariants(
              (prodvariants = [
                ...res.data.message.variants.sort(function (a: any, b: any) {
                  return a.Weight - b.Weight;
                }),
              ])
            );
            // setVariantWeight(prodvariants[0].Weight)
          })
          .catch((err) => console.log(err));
      };
      getVariants(productdetail.variant_of);
    } else {
      console.log("inside useeffect hook but condition not applied");
    }
  }, [productdetail]);

  // console.log(param);
  // console.log(param.productId?.split("-"));
  const getSelectedProductWeight = param.productId?.split("-");
  // console.log(getSelectedProductWeight?.[2]);
  // console.log("product variants", prodvariants);

  const handleHighLighting = (code: any, weight: any) => {
    console.log(code);
    setVariantWeight(weight);
  };
  const handleColor = (col: string) => {
    // console.log("in detail parent page", col);
    setcolorValue(col);
  };
  useEffect(() => {
    // document.title = `${productdetail.name}`;
    document.title = productdetail.name
      ? `${productdetail.name}`
      : "Loading...";
    // setTimeout
  }, [productdetail.name]);

  console.log("prev and next in detail", productPrevNextData);

  const handlePrevProductShow = () => {
    // const gettingOnlyKeys
    const findingBTN = productPrevNextData.find((obj: any) =>
      obj.hasOwnProperty(params.productId)
    );
    console.log("prev and next in find prev btn", findingBTN);
  };

  const handleNextProductShow = () => {
    console.log(
      "prev and next in next func show",
      params.categoryId,
      params.subCategoryId,
      params.productId
    );
  };
  // console.log("@@image", productdetail.slideshow_image);
  console.log(productdetail, "{ascending");
  return (
    <>
      {productdetail.name ? (
        <>
          <div>
            {!shows ? (
              <>
                <ToastNotification
                  setShow={setShow1}
                  show={show1}
                  content="Product Added To Wishlist!"
                />
                <ToastNotification
                  setShow={setShow2}
                  show={show2}
                  content="Product Removed From Wishlist!"
                />
                <div className="breadcrumb-sec">
                  <div className="container-lg">
                    <div className="row">
                      <div className="col-12">
                        {param.subCategoryId !== `undefined` ? (
                          param.subCategoryId ? (
                            <Breadcrumb
                              breadcrumbs={[
                                { to: "/categories", name: param.categoryId },
                                {
                                  to: "/sub-category/" + param.categoryId,
                                  name: param.subCategoryId,
                                },
                                {
                                  to:
                                    "/products/" +
                                    param.categoryId +
                                    "/" +
                                    param.subCategoryId +
                                    "?page=1",
                                  name: "Products",
                                },
                                {
                                  to:
                                    "/product-detail/" +
                                    param.categoryId +
                                    "/" +
                                    param.subCategoryId +
                                    "/" +
                                    productdetail.name,
                                  name: productdetail.name,
                                },
                              ]}
                            />
                          ) : (
                            <Breadcrumb
                              breadcrumbs={[
                                {
                                  to: "/categories",
                                  name: productdetail.level_1_category,
                                },
                                {
                                  to:
                                    "/sub-category/" +
                                    productdetail.level_1_category,
                                  name: productdetail.level_2_category,
                                },
                                {
                                  to: "#",
                                  name: "Products",
                                },
                                {
                                  to: "#",
                                  name: param.productId,
                                },
                              ]}
                            />
                          )
                        ) : (
                          <Breadcrumb
                            breadcrumbs={[
                              {
                                to: "/categories",
                                name: productdetail.level_1_category,
                              },
                              { to: "#", name: productdetail.level_2_category },
                              {
                                to: "#" + param.categoryId,
                                name: "All Products",
                              },
                              // {
                              //   to: "/all-product/" + param.categoryId,
                              //   name: "All Products",
                              // },
                              {
                                to:
                                  "/product-detail/" +
                                  param.categoryId +
                                  "/" +
                                  param.subCategoryId +
                                  "/" +
                                  productdetail.name,
                                name: productdetail.name,
                              },
                            ]}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="mt-4 mb-2">
                  <Purity
                    funcColor={handleColor}
                    handleSortBySeq={handleSortBySeq}
                    sortBySeq={sortBySeq}
                    handlesortByDesc={handlesortByDesc}
                    sortByDesc={sortByDesc}
                  />
                </div>
                <div className="container-fluid">
                  <div id="content" className="full">
                    <div className="product row">
                      <div className="col-lg-5">
                        <div className="image-block ms-3 me-3 ">
                          <Link to="#">
                            {wishlistData[productdetail.name] ? (
                              <i
                                className="fa fa-heart mt-2 me-1 float-end fill-heart product-card-icon"
                                onClick={() =>
                                  handleWhishlist(productdetail.name, false)
                                }
                                style={{ fontSize: "24px", color: "red" }}
                              />
                            ) : (
                              <i
                                className="fa fa-heart-o mt-2 me-1 float-end product-card-icon"
                                onClick={() =>
                                  handleWhishlist(productdetail.name, true)
                                }
                                style={{ fontSize: "24px", color: "red" }}
                              />
                            )}
                          </Link>
                          <ProductImage
                            Imageslideshow={productdetail.slideshow_image}
                          />
                        </div>
                      </div>

                      <div className="col-lg-7">
                        <div
                          className="variant-class"
                          style={{ margin: "0 0 5px 20px" }}
                        >
                          {prodvariants &&
                            prodvariants.map((item: any) => {
                              return param.productId === item.item_code ? (
                                <Link
                                  to={
                                    "/product-detail/" +
                                    param.categoryId +
                                    "/" +
                                    param.subCategoryId +
                                    "/" +
                                    item.item_code
                                  }
                                  className="btn btn-variant  ms-2"
                                  style={{
                                    color: "white",
                                    backgroundColor: "rgb(3,15,39)",
                                  }}
                                  onClick={() =>
                                    handleHighLighting(
                                      item.item_code,
                                      item.Weight
                                    )
                                  }
                                >
                                  {/* <span>{item.Category} </span> */}
                                  <span>{item.Weight}</span>
                                </Link>
                              ) : (
                                <Link
                                  to={
                                    "/product-detail/" +
                                    param.categoryId +
                                    "/" +
                                    param.subCategoryId +
                                    "/" +
                                    item.item_code
                                  }
                                  className="btn btn-variant  ms-2"
                                  onClick={() =>
                                    handleHighLighting(
                                      item.item_code,
                                      item.Weight
                                    )
                                  }
                                >
                                  {/* <span>{item.Category} </span> */}
                                  <span>{item.Weight}</span>
                                </Link>
                              );
                            })}
                        </div>
                        <div>
                          <AddFieldForm
                            title={productdetail.name}
                            purity={productdetail.stock_uom}
                            weight={productdetail.weight_per_unit}
                            outer_size={parseInt(productdetail.length)}
                            // weight={productdetail.weight_abbr}
                            net_weight={productdetail.net_weight}
                            color={colorValue}
                            prodCodes={arrayOfCodes}
                            bom_factory_code={productdetail.bom_factory_code}
                            factory_name={params.categoryId}
                            level_2_category={productdetail.level_2_category}
                            market_design_name={
                              productdetail.market_design_name
                            }
                            reject_button_value={
                              productdetail.reject_button_value
                            }
                          />
                        </div>
                        <div className="container">
                          <div className="row ">
                            <div className="col-md-4">
                              <div className="d-flex justify-content-start">
                                <div className="me-3">
                                  {prevValue !== null &&
                                  productPrevNextData.find((obj: any) =>
                                    obj.hasOwnProperty(params.productId)
                                  )?.[productID]?.prev !== null ? (
                                    <Link
                                      to={
                                        "/product-detail/" +
                                        params.categoryId +
                                        "/" +
                                        params.subCategoryId +
                                        "/" +
                                        prevValue
                                      }
                                    >
                                      <i
                                        className="fa fa-arrow-circle-left fa-lg"
                                        style={{ color: "#030f27" }}
                                        aria-hidden="true"
                                      >
                                        Prev
                                      </i>
                                    </Link>
                                  ) : (
                                    <i
                                      className="fa fa-arrow-circle-left fa-lg disabled"
                                      aria-hidden="true"
                                    >
                                      Prev
                                    </i>
                                  )}
                                </div>
                                <div>
                                  {nextValue !== null &&
                                  productPrevNextData.find((obj: any) =>
                                    obj.hasOwnProperty(params.productId)
                                  )?.[productID]?.next !== null ? (
                                    <Link
                                      to={
                                        "/product-detail/" +
                                        params.categoryId +
                                        "/" +
                                        params.subCategoryId +
                                        "/" +
                                        nextValue
                                      }
                                    >
                                      <i
                                        className="fa fas fa-arrow-circle-right fa-lg"
                                        style={{ color: "#030f27" }}
                                        aria-hidden="true"
                                      >
                                        Next
                                      </i>
                                    </Link>
                                  ) : (
                                    <i
                                      className="fa fa-arrow-circle-right fa-lg disabled"
                                      aria-hidden="true"
                                    >
                                      Next
                                    </i>
                                  )}
                                </div>
                              </div>
                            </div>
                            <div className="col-md-3"></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <div className="container-lg fill-window">
                <div className="zoom-img">
                  <button
                    className="btn-close zoom-img-btn"
                    onClick={() => setShows(false)}
                  ></button>
                  <img
                    src={
                      productdetail.image
                        ? CONSTANT.BASE_URL + productdetail.image
                        : noimage
                    }
                    alt=""
                    // className="onclick-img"
                  />
                </div>
              </div>
            )}
          </div>
        </>
      ) : (
        <Norecord
          heading=""
          img={img}
          content="Sorry for disappointing you! We’re unable to find any relevant data"
        />
      )}
    </>
  );
};
export default ProductDetail;

import axios from "axios";
import { DEFAULT_API_CONFIG } from "../config/api-config";

const PostRejectionNote = async (params: any) => {
  let response: any;

  let login = localStorage.getItem("token");
  const url: string = `${DEFAULT_API_CONFIG.url}/api/method/digitalcatalog_api_erpnext.api.product_list.reject_new_arrival_item`;
  const config = {
    headers: {
      Authorization: login && JSON.parse(login)?.access_token,
    },
  };
  await axios
    .post(`${url}?`, params ,config)
    .then((res: any) => {
      response = res;
    })
    .catch((err: any) => {
      console.log(err);
      response = err;
    });
  return response;
};

export default PostRejectionNote;

import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { RootState } from "../../../store/root-reducer";
import { dispatchOrderAPI, orderdetailAPI } from "../../../store/screens/order";
import AddReviewAPI from "../../../services/api/review-api";
import updateSalesOrderStatusAPI from "../../../services/api/update-sales-order-status-api";
import { useNavbar } from "../../../components/navbar/components/navbar-hooks";
import { Toast } from "react-bootstrap";
import { cartAPI, PostCartAPI } from "../../../store/screens/cart";

export const useOrderDetail = () => {
  const dispatch = useDispatch();
  const param = useParams();
  const navigate = useNavigate();
  const urlParams = new URLSearchParams(window.location.search);
  const status = urlParams.get("status");
  const orderData = useSelector((state: RootState) => state.orders.orderdetail);
  console.log("order detail params", param);
  const { cartCount } = useNavbar();
  console.log("cartCount", cartCount);

  let params = {
    name: param.orderId,
    status: status,
  };
  const [reOrderData, setReOrderData] = useState(params);
  useEffect(() => {
    dispatch(orderdetailAPI(params));
  }, []);

  const [date, setDate] = useState("");
  const [finalWeight, setFinalWeight] = useState(0);

  const [reviewState, setReviewState] = useState<string>("");
  const [show, setShow] = useState(false);
  const [showErr, setShowErr] = useState(false);
  const [customerUpdate, setCustomerUpdate] = useState(false);

  let login = localStorage.getItem("token");

  const handleOrderConfirmStatusUpdate = async (orderName: any) => {
    let user = localStorage.getItem("user");
    let users = user ? JSON.parse(user) : null;

    // console.log("dispatch",orderName);
    // console.log("dispatch",weight);
    let userParam = {
      user: users,
    };
    let params = {
      order_id: orderName,
    };
    dispatch(dispatchOrderAPI(params));
    navigate("/order-list");
  };

  const callAddReviewAPI = async (
    order_id: string,
    product_name: string,
    customer_name: string,
    order_date: string
  ) => {
    // console.log(
    //   "review details",
    //   order_id,
    //   product_name,
    //   customer_name,
    //   order_date,
    //   reviewState
    // );

    let callAddReviewAPI: any = await AddReviewAPI(
      order_id,
      product_name,
      customer_name,
      order_date,
      reviewState
    );
    // console.log("review details api", callAddReviewAPI);
    if (
      callAddReviewAPI?.status === 200 &&
      callAddReviewAPI?.data?.message?.status === "success"
    ) {
      setShow(true);
      setTimeout(() => {
        setShow(false);
      }, 3000);
      dispatch(orderdetailAPI(params));
    } else {
      setShowErr(true);
      setTimeout(() => {
        setShowErr(false);
      }, 3000);
    }
  };

  const callUpdateSalesOrderStatusAPI: any = async (soisd_item: any) => {
    const reqParams = {
      id: soisd_item,
      customer_review: reviewState,
    };
    let callUpdateSalesOrderStatus: any = await updateSalesOrderStatusAPI(
      reqParams
    );

    if (callUpdateSalesOrderStatus?.hasOwnProperty("data")) {
      dispatch(orderdetailAPI(params));
    }
  };
  const [showReorder, setShowReorder] = useState(false);
  const orderDataReorder = useSelector(
    (state: RootState) => state.orders.orderdetail
  );
  // const orderReOrderCustomerName = orderDataReorder?.cust_name;
  const getCustomerName: any = localStorage.getItem("customer-name");
  // const [editableCustomerName, setEditableCustomerName] = useState(
  //   orderReOrderCustomerName || getCustomerName
  // );
  const orderReOrderCustomerName = orderData?.cust_name;
  const [editableCustomerName, setEditableCustomerName] = useState(
    orderReOrderCustomerName || getCustomerName
  );

  const handleCustomerName = (e: any) => {
    setEditableCustomerName(e.target.value);
  };
  const reOrderOrderDetail = orderData?.data?.map((ele: any) => ele?.orders);
  // Declare variables outside
  let itemCode: any;
  let reorderPurity: any;
  let orderDetails: any = [];
  let colour: any, qty: any, size: any, weight: any;
  // Accessing item_code if there is an extra array level
  if (
    Array.isArray(reOrderOrderDetail) &&
    reOrderOrderDetail.length > 0 &&
    Array.isArray(reOrderOrderDetail[0]) &&
    reOrderOrderDetail[0].length > 0
  ) {
    itemCode = reOrderOrderDetail[0][0].item_code;
    reorderPurity = reOrderOrderDetail[0][0].purity;
    console.log("Item Code:", itemCode);

    // Accessing the order array
    orderDetails = reOrderOrderDetail[0][0].order;
    if (Array.isArray(orderDetails) && orderDetails.length > 0) {
      ({ colour, qty, size, weight } = orderDetails[0]);
    }
    console.log("Order Data:", orderDetails);
  } else {
    console.log("Data is not in expected format");
  }

  let user = localStorage.getItem("user");
  let users = user ? JSON.parse(user) : null;
  let userData = {
    user: users,
  };

  // Reorder functionality
  const handleReorder = async (customerName: any) => {
    if (cartCount === 0) {
      // Initialize arrays to store processed data
      const paramsArray: any = [];

      // Loop through the outer array
      reOrderOrderDetail.forEach((productArray: any[]) => {
        productArray.forEach((product: any) => {
          const itemCode = product.item_code;
          const reorderPurity = product.purity;

          // Accessing the order array
          const orderDetails = product.order;

          // Initialize variables for product details
          let colour = "";
          let qty = 0;
          let size = "";
          let weight = 0;

          if (Array.isArray(orderDetails) && orderDetails.length > 0) {
            // Initialize the qty_size_list array for this product

            // Assuming the first element contains the necessary details
            ({ colour, qty, size, weight } = orderDetails[0]);
          }
          const qtySizeList = orderDetails.map((order: any) => ({
            qty: order.qty,
            size: order.size,
            colour: order.colour,
          }));

          // Create params object for this product
          const params = {
            cust_name: editableCustomerName || customerName,
            item_code: itemCode,
            purity: reorderPurity,
            qty_size_list: qtySizeList,
            colour: colour,
            remark: "",
            user: users,
            wastage: "",
          };

          paramsArray.push(params);
        });
      });
      console.log("paramsArray", paramsArray);
      // await dispatch(PostCartAPI(paramsArray)); // Call PostCartAPI
      // Send each object separately
      for (const params of paramsArray) {
        console.log("Sending params:", params);
        await dispatch(PostCartAPI(params)); // Call PostCartAPI with each params object
      }
      await dispatch(cartAPI(userData)); // Call cartAPI
      navigate("/cart");
    } else {
      setShowReorder(true);
      console.log("Reorder unsuccessful as your Cart is not empty");
    }
  };

  const handleSaveCustomerNameReorder = async (
    item_code: any,
    purity: any,
    qty: any,
    size: any,
    colour: any
  ) => {
    let params: any;
    params = {
      cust_name: editableCustomerName,
      item_code: item_code,
      purity: purity,
      qty_size_list: [
        {
          qty: qty,
          size: size,
          colour: colour,
        },
      ],
      colour: colour,
      remark: "",
      user: users,
      wastage: "",
    };
    console.log("qty ", params);
    await dispatch(PostCartAPI(params));
    setCustomerUpdate(true);
    await dispatch(cartAPI(userData));
  };

  return {
    orderData,
    date,
    finalWeight,
    setReviewState,
    callAddReviewAPI,
    show,
    setShow,
    showErr,
    setShowErr,
    handleOrderConfirmStatusUpdate,
    callUpdateSalesOrderStatusAPI,
    reviewState,
    handleReorder,
    showReorder,
    setShowReorder,
    reOrderData,
    editableCustomerName,
    handleCustomerName,
    handleSaveCustomerNameReorder,
    customerUpdate,
    setCustomerUpdate,
  };
};

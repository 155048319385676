import { useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../store/root-reducer";

export const useLogin=()=>{
  const login = useSelector((state: RootState) => state.login);
  const [isRevaled, setIsRevaled] = useState(false);
  const hideshow = () => {
    setIsRevaled(!isRevaled);
  };

  return{
    login,
    isRevaled,
    hideshow
  }
}
import axios from "axios";
import { DEFAULT_API_CONFIG } from "../config/api-config";

export const getRefCodesListAPI: any = async (customer_name: any) => {
    let response;
    let login: any = localStorage.getItem("token")
    const config = {
        headers: {
            Authorization: login && JSON.parse(login)?.access_token
        }
    }
    const url = `${DEFAULT_API_CONFIG?.url}api/method/digitalcatalog_api_erpnext.digitalcatalog_api_erpnext.doctype.customer_item_reference_code.customer_item_reference_code_api.get_customer_item_by_customer_name_and_item_code?customer_name=${customer_name}`;

    await axios.get(`${url}`, config).then((res: any) => {
        response = res;
    }).catch((err: any) => {

        response = err;
    })
    return response
};


const createNewQuickOrderRecord: any = async (values: any) => {
    let response: any;



    let login = localStorage.getItem("token");
    const url = `${DEFAULT_API_CONFIG?.url}api/method/digitalcatalog_api_erpnext.api.quotation.create_sales_quotation`;

    const config = {
        headers: {
            Authorization: login && JSON.parse(login)?.access_token,
        }
    }

    await axios.post(`${url}`, values, config).then((res: any) => {
        response = res;
    }).catch((err: any) => {
        response = err;
    })

    return response;
};

export default createNewQuickOrderRecord;

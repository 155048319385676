// import { convertCompilerOptionsFromJson } from "typescript";
import store  from "../../store/store";
import { callAPI } from "../config/api-core";
import { CategoriesAPIMethods } from "../methods/categories-api-method";

const subcategoryFetch = async (category: any): Promise<any> => {

  // if (category === "ARC" || category === "ARG" || category === "ARF") {
  //   let login = localStorage.getItem('token');
  //   let header = {
  //     Authorization: login && JSON.parse(login)?.access_token,
  //   };
  //   let params = '&limit_page_length=None&filters=[["parent_item_group","=","' + category + '"]]';
  //   const response = await callAPI(
  //     CategoriesAPIMethods.subcategoriesList + params,
  //     true,
  //     {},
  //     header,
  //     false,
  //     false
  //   );
  //   return response;
  // }


  let login = localStorage.getItem('token');
  let header = {
    Authorization: login && JSON.parse(login)?.access_token,
  };
  let params = '&limit_page_length=None&filters=[["parent_item_group","=","' + category + '"],["is_group","=",1]]';
  const response = await callAPI(
    CategoriesAPIMethods.subcategoriesList + params,
    true,
    {},
    header,
    false,
    false
  );
  return response;


};


const getSubcategoriesListAPI = (category: any) => subcategoryFetch(category);
// eslint-disable-next-line import/no-anonymous-default-export
export default {
  getSubcategoriesListAPI,
};

import { PostCartAPI, cartAPI, RemoveAllItemsAPI } from "../store/screens/cart";
import { Formik, Field, Form, FieldArray, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import "../assets/css/addField.css";
import { Link } from "react-router-dom";
import { useEffect, useState, useRef } from "react";
import { Toast, ToastContainer } from "react-bootstrap";
import ToastNotification from "./toast-notification";
import { usePurity } from "./purity/purity-hooks";
import { useCart } from "../pages/cart/components/cart-hooks";
import { useProducts } from "../pages/products/components/product-hooks";
import { useParams } from "react-router-dom";
import PopupModal from "./popup-modal";
import PostRejectionNote from "../services/api/rejection-note-api";
import { productdetailAPI } from "../store/screens/products";

interface props {
  title: string;
  purity?: any;
  bom_factory_code?: any;
  onHide?: any;
  image?: any;
  weight?: any;
  outer_size?: any;
  net_weight?: any;
  setRev?: any;
  color?: any;
  prodCodes?: any;
  level_2_category?: any;
  market_design_name?: any;
  hasBroadness?: any;
  storeLength?: any;
  factory_name?: any;
  custom_size?: any;
  reject_button_value?: 0 | 1;
}

const validationSchema = Yup.object().shape({
  Inputs: Yup.array().of(
    Yup.object().shape({
      qty: Yup.number()
        .integer("you must specify a quantity without decimal")
        .typeError("you must specify a number")
        .notOneOf([0], "not to be zero")
        // .matches(/^[0-9]+$/, "only numbers")
        .required("Field Required"),

      size: Yup.number()
        .notOneOf([0], "not to be zero")
        .typeError("you must specify a number")
        .required("Field Required"),
    })
  ),
});

const AddFieldForm = ({
  title,
  onHide,
  bom_factory_code,
  image,
  weight,
  outer_size,
  net_weight,
  setRev,
  color,
  prodCodes,
  level_2_category,
  market_design_name,
  hasBroadness,
  storeLength,
  factory_name,
  custom_size,
  reject_button_value,
}: props) => {
  // const { arrayOfCodes } = useProducts();
  const [formWeight, SetFormWeight] = useState([]);
  const [wastageField, setwastageField] = useState("");
  const idxRef: any = useRef(null);
  console.log("bom_factory_code", bom_factory_code);
  const arrayOfDefaultSizes: any = [
    { id: 1, size: 8 },
    { id: 1, size: 8.5 },
    { id: 1, size: 16 },
    { id: 1, size: 18 },
    { id: 2, size: 20 },
    { id: 3, size: 22 },
    { id: 4, size: 24 },
    { id: 5, size: 26 },
  ];
  let isEmptyFieldRemoved: any = useRef(false);
  let [defaultSizeState, setDefaultSizeState] = useState<any>([]);
  // let defaultSizesRef: any = useRef([]);

  const dispatch = useDispatch();
  let user = localStorage.getItem("user");
  console.log(localStorage, "localStorage");
  let users = user ? JSON.parse(user) : null;
  let param = {
    user: users,
  };
  let purity: any = localStorage.getItem("localPurity");

  const { subCategoryId }: any = useParams();

  let productCodes: string[] = [];

  if (localStorage.getItem("product-codes")) {
    productCodes = JSON.parse(localStorage.getItem("product-codes") || "");
  }

  const [finalWeight, setFinalWeight] = useState(0);
  const [show, setShow] = useState(false);
  const [showIsCustomerNameAdded, setShowIsCustomerNameAdded] = useState(false);
  const [doesThisCategoryExists, setDoesThisCategoryExists] = useState(false);
  const [isRejected, setIsRejected] = useState<boolean>(false);
  const [rejectionNote, setRejectionNote] = useState<string>("");
  // console.log(finalWeight);
  const { carts } = useCart();

  const getSelectedPurity = localStorage.getItem("localPurity");
  const getSelectedColor: any = localStorage.getItem("selectedColor");
  const getCustomerName: any = localStorage.getItem("customer-name");
  const getCategoryName: any = localStorage.getItem("category-name");

  const [activeColor, setActiveColor] = useState<any>(
    getSelectedColor !== null ? getSelectedColor : color
  );

  const clearCart = async (e: any) => {
    e.preventDefault();
    let user = localStorage.getItem("user");
    let users = user ? JSON.parse(user) : null;
    let param = {
      user: users,
    };
    await dispatch(RemoveAllItemsAPI(param));
    await dispatch(cartAPI(param));
    await localStorage.removeItem("category-name");
    hideModal();
  };

  const hideModal = () => {
    setDoesThisCategoryExists(false);
  };

  useEffect(() => {
    setActiveColor(color);
  }, [color]);

  const addDefaultSizes = (sizeValue: any) => {
    console.log("size ", sizeValue);
    const checkIfValuesExists = defaultSizeState?.includes(sizeValue);

    if (checkIfValuesExists) {
      const getIndexOfValue = defaultSizeState?.indexOf(sizeValue);
      const removeExistingValues: any = defaultSizeState?.filter(
        (value: any) => value !== sizeValue
      );
      setDefaultSizeState([...removeExistingValues]);
      console.log("default sizes function", defaultSizeState);
      return { index: getIndexOfValue, exists: true };
    } else {
      defaultSizeState?.unshift(sizeValue);
      return false;
    }
  };
  const onSubmitRejectionNote = async () => {
    const params = {
      item_code: title,
      rejection_note: rejectionNote,
    };
    const detailParams = {
      item_code: title,
      fields: "*",
      user: users,
    };
    if (rejectionNote !== "") {
      const postNote = await PostRejectionNote(params);
      if (postNote?.data?.message?.msg === "success") {
        setIsRejected(false);
        dispatch(productdetailAPI(detailParams));
      }
    }
  };
  // console.log("hasbroadness", hasBroadness);
  return (
    <>
      <div>
        <ToastNotification
          setShow={setShow}
          show={show}
          content="Product Added!"
        />
        <ToastNotification
          setShow={setShowIsCustomerNameAdded}
          show={showIsCustomerNameAdded}
          content="Please Add Customer Name!"
        />
      </div>

      <Formik
        initialValues={{
          // unitSize: "inch",
          remark: "",
          Inputs: [
            { qty: "", weight: "", size: "", colour: `${getSelectedColor}` },
          ],
        }}
        validationSchema={validationSchema}
        onSubmit={async (values, { resetForm }: any) => {
          console.log("check for variants", values.Inputs);

          const size_list = values?.Inputs?.map((obj: any) => {
            const { weight, ...newObj } = obj;
            return newObj;
          });

          if (getCustomerName !== null) {
            // console.log("customer name", getCustomerName);
            if (values && values.Inputs.length) {
              let params: any;
              if (carts && carts.length > 0) {
                // console.log("if");
                // console.log(values.Inputs);
                params = {
                  user: users,
                  cust_name: getCustomerName,
                  item_code: title || "GRIP004A0002C",
                  purity: carts[0].purity,
                  // colour: productColor,
                  colour: getSelectedColor,
                  remark: values.remark,
                  wastage: wastageField,
                  qty_size_list: size_list,
                };
              } else {
                // console.log("in else");
                // values.Inputs[0].colour = productColor
                // console.log(values.Inputs);
                params = {
                  user: users,
                  cust_name: getCustomerName,
                  item_code: title || "GRIP004A0002C",
                  purity: purity,
                  // colour: productColor,
                  colour: getSelectedColor,
                  remark: values.remark,
                  wastage: wastageField,
                  qty_size_list: size_list,
                };
              }
              // console.log("dispatching");
              await dispatch(PostCartAPI(params));
              await dispatch(cartAPI(param));
              resetForm({ values: "" });
              setwastageField(" ");
              setShow(true);
              setRev(true);
              onHide();
              localStorage.removeItem("category-name");
              //  window.location.reload();
            }
          } else {
            setShowIsCustomerNameAdded(!showIsCustomerNameAdded);
          }
        }}
      >
        {({ values, setFieldValue, handleChange }) => (
          <Form>
            <FieldArray
              name="Inputs"
              render={(arrayHelpers) => {
                const { form } = arrayHelpers;
                const { values } = form;
                let { Inputs } = values;

                if (Inputs[idxRef.current] === undefined) {
                  idxRef.current = null;
                } else {
                  Inputs[idxRef.current].weight = (
                    (weight / outer_size) *
                    Inputs[idxRef.current].size
                  ).toFixed(2);
                }
                return (
                  <div className="container-fluid">
                    <div className="row">
                      <div className="col-12 pt-0 p-3">
                        <div className="product-details">
                          <div className="row p-0">
                            <div className="col-6 mt-3 mb-3 p-0">
                              <h6>Product Code: {title}</h6>
                              {bom_factory_code !== null && (
                                <h6 className="mt-2">
                                  BOM Factory Code: {bom_factory_code}
                                </h6>
                              )}

                              {level_2_category === "BALL CHAINS" && (
                                <h6
                                  className="mt-2"
                                  style={{ lineHeight: "1.5em" }}
                                >
                                  Market Design Name: {market_design_name}
                                </h6>
                              )}

                              <h6 className="mt-2">
                                Gross Wt. : {parseFloat(weight).toFixed(2)}gm
                                {/* Gross Wt. : {weight.toFixed(2)}gm */}
                              </h6>
                              {(level_2_category === "MANGALSUTRA" ||
                                level_2_category === "IMP PREMIUM") && (
                                <h6 className="mt-2">
                                  Net Wt. : {parseFloat(net_weight)}gm
                                </h6>
                              )}
                            </div>
                            {/* 
                            <div className="col-6 d-flex justify-content-end mb-4 mt-3 p-0">
                              <Link
                                to="#"
                                className="link-addsize"
                                onClick={() => {
                                  isEmptyFieldRemoved.current = true;
                                  arrayHelpers.push({
                                    qty: "",
                                    size: "",
                                    colour: "Yellow",
                                  });
                                }}
                              >
                                Add Size
                              </Link>
                            </div> */}

                            <div className="p-0">
                              <div>
                                <h6>
                                  These product is available in below sizes:
                                </h6>
                              </div>
                              <div className="d-flex p-0 mt-2 mb-3">
                                <div>
                                  {custom_size?.map(
                                    (size_val: string, index: any) => {
                                      return (
                                        <button
                                          key={index}
                                          id={index}
                                          type="button"
                                          className={`btn custom-border-default-size 
                                          ${
                                            defaultSizeState?.includes(
                                              size_val.toString()
                                            )
                                              ? "size-added-class"
                                              : "size-not-added"
                                          }
                                          
                                          `}
                                          onClick={() => {
                                            const checkIfSizeAlreadyAdded =
                                              addDefaultSizes(size_val);
                                            const { index, exists }: any =
                                              checkIfSizeAlreadyAdded;
                                            if (exists) {
                                              arrayHelpers?.remove(index);
                                            } else {
                                              // if (
                                              //   !isEmptyFieldRemoved.current
                                              // ) {
                                              //   isEmptyFieldRemoved.current =
                                              //     true;
                                              //   arrayHelpers.remove(0);
                                              // }

                                              const hasEmptySize = Inputs.some(
                                                (item: any) => item.size === ""
                                              );
                                              if (hasEmptySize) {
                                                arrayHelpers.remove(0);
                                              }
                                              arrayHelpers.unshift({
                                                qty: "",
                                                weight: (
                                                  (weight / outer_size) *
                                                  parseInt(size_val)
                                                ).toFixed(2),
                                                size: `${parseInt(size_val)}`,
                                                colour: "Yellow",
                                              });
                                            }
                                          }}
                                        >
                                          <Link
                                            to="#"
                                            className={`link-underline-remove font-increase
                                            ${
                                              defaultSizeState?.includes(
                                                size_val
                                              )
                                                ? "size-added-class"
                                                : "size-not-added"
                                            }
                                              `}
                                          >
                                            {size_val}
                                          </Link>
                                        </button>
                                      );
                                    }
                                  )}

                                  <Link
                                    to="#"
                                    className="ms-2 link-addsize font-increase"
                                    onClick={() => {
                                      isEmptyFieldRemoved.current = true;
                                      arrayHelpers.push({
                                        qty: "",
                                        weight: "",
                                        size: "",
                                        colour: "Yellow",
                                      });
                                    }}
                                  >
                                    Add Custom Size
                                  </Link>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="row border p-0">
                            <div className="col-2 border-end p-0">
                              <label className="form-label label-hi">
                                Purity
                              </label>
                            </div>
                            <div className="col-2 border-end p-0">
                              <label className="form-label label-hi">
                                Color
                              </label>
                            </div>
                            {factory_name === "ARC" && (
                              <div className="col-2 border-end p-0">
                                <label className="form-label label-hi">
                                  Weight
                                </label>
                              </div>
                            )}

                            {factory_name === "ARC" ? (
                              <div className="col-2 border-end p-0">
                                <label className="form-label label-hi">
                                  Size(Inch)
                                </label>
                              </div>
                            ) : (
                              <div className="col-3 border-end p-0">
                                <label className="form-label label-hi">
                                  Size(Inch)
                                </label>
                              </div>
                            )}

                            {factory_name === "ARC" ? (
                              <div className="col-2 p-0">
                                <label className="form-label label-hi">
                                  Quantity
                                </label>
                              </div>
                            ) : (
                              <div className="col-3 p-0">
                                <label className="form-label label-hi">
                                  Quantity
                                </label>
                              </div>
                            )}
                          </div>
                          {Inputs.map((Input: any, idx: any) => (
                            <div key={idx}>
                              <div className="row border p-0">
                                <div className="col-md-2 border-end d-flex align-items-center">
                                  <label className="form-label label-hi">
                                    {getSelectedPurity}
                                  </label>
                                </div>
                                <div className="col-md-2 border-end d-flex align-items-center">
                                  <select
                                    name={`Inputs[${idx}].colour`}
                                    id="color"
                                    onChange={handleChange}
                                    defaultValue={`${getSelectedColor}`}
                                  >
                                    <option value="Yellow">Yellow</option>
                                    <option value="Rose">Rose</option>
                                    <option value="White">White</option>
                                  </select>
                                </div>
                                {factory_name === "ARC" && (
                                  <div className="col-md-2 border-end">
                                    <Field
                                      name={`Inputs[${idx}].weight`}
                                      // placeholder="Enter Size"
                                      className="form-control line-hi"
                                    />
                                    <div className="empty">
                                      {" "}
                                      <ErrorMessage
                                        name={`Inputs[${idx}].weight`}
                                      />
                                    </div>
                                  </div>
                                )}

                                {/* {hasBroadness ? :  } */}
                                {factory_name === "ARC" ? (
                                  <div className="col-md-2 border-end">
                                    <Field
                                      name={`Inputs[${idx}].size`}
                                      className="form-control line-hi"
                                      onChange={(e: any) => {
                                        handleChange(e);
                                        idxRef.current = idx; // Let Formik handle the change
                                        console.log(
                                          "Changed field index:",
                                          idx
                                        );
                                      }}
                                    />

                                    <div className="empty">
                                      <ErrorMessage
                                        name={`Inputs[${idx}].size`}
                                      />
                                    </div>
                                  </div>
                                ) : (
                                  <div className="col-md-3 border-end">
                                    <Field
                                      name={`Inputs[${idx}].size`}
                                      className="form-control line-hi"
                                    />

                                    <div className="empty">
                                      <ErrorMessage
                                        name={`Inputs[${idx}].size`}
                                      />
                                    </div>
                                  </div>
                                )}

                                {factory_name === "ARC" ? (
                                  <div className="col-md-2 border-end">
                                    <Field
                                      name={`Inputs[${idx}].qty`}
                                      className="form-control line-hi"
                                    />

                                    <div className="empty">
                                      <ErrorMessage
                                        name={`Inputs[${idx}].qty`}
                                      />
                                    </div>
                                  </div>
                                ) : (
                                  <div className="col-md-3 border-end">
                                    <Field
                                      name={`Inputs[${idx}].qty`}
                                      className="form-control line-hi"
                                    />

                                    <div className="empty">
                                      <ErrorMessage
                                        name={`Inputs[${idx}].qty`}
                                      />
                                    </div>
                                  </div>
                                )}

                                <div className="col-md-2 d-flex justify-content-center align-items-center p-0">
                                  {/* {idx > 0 && ( */}
                                  <button
                                    type="button"
                                    className="btn-close line-hi btn-cross"
                                    aria-label="Close"
                                    onClick={() => {
                                      const checkIfSizeAlreadyAdded =
                                        addDefaultSizes(
                                          parseInt(Inputs[idx]?.size)
                                        );
                                      const { index, exists }: any =
                                        checkIfSizeAlreadyAdded;
                                      if (exists) {
                                        arrayHelpers.remove(index);
                                      } else {
                                        arrayHelpers.remove(idx);
                                      }
                                    }}
                                  />
                                </div>
                              </div>
                            </div>
                          ))}

                          <div className="row p-0">
                            <div className="col-12 p-0">
                              <div className="wastage-field mt-3">
                                <input
                                  type="text"
                                  className="form-control line-hi  w-50"
                                  placeholder="Wastage"
                                  value={wastageField}
                                  onChange={(e: any) =>
                                    setwastageField(e.target.value)
                                  }
                                />
                              </div>
                            </div>
                            <div className="col-12 p-0 d-flex justify-space-between">
                              <textarea
                                className="remark ps-3 pt-2"
                                name="remark"
                                placeholder="Enter Note"
                                id=""
                                value={form.values.remark || ""}
                                onChange={(e: any) =>
                                  setFieldValue(`remark`, e.target.value)
                                }
                              />
                              {isRejected === true && (
                                <textarea
                                  className="remark ps-3 pt-2 ms-2"
                                  placeholder="Enter Rejection Note"
                                  onChange={(e: any) =>
                                    setRejectionNote(e.target.value)
                                  }
                                />
                              )}
                            </div>
                            <div className="col-12 d-flex justify-content-end mb-2 p-0 ">
                              {/* {Inputs.map((Input: any, index: any) => ( */}
                              <>
                                {reject_button_value !== undefined && (
                                  <div>
                                    {reject_button_value === 0 &&
                                    isRejected === true ? (
                                      <button
                                        className="btn  btn-cart me-2"
                                        onClick={onSubmitRejectionNote}
                                      >
                                        REJECT
                                      </button>
                                    ) : reject_button_value === 0 ? (
                                      <button
                                        className="btn  btn-cart me-2"
                                        onClick={() => setIsRejected(true)}
                                      >
                                        REJECT
                                      </button>
                                    ) : (
                                      <button
                                        type="submit"
                                        className=" btn btn-cart me-2"
                                        disabled
                                      >
                                        Rejected
                                      </button>
                                    )}
                                  </div>
                                )}
                                {prodCodes.find(
                                  (name: any) => name === title
                                ) ? (
                                  <button
                                    type="submit"
                                    className="btn mt-4 text-dark border added "
                                  >
                                    Added
                                  </button>
                                ) : (
                                  <button
                                    type="submit"
                                    className=" btn btn-cart"
                                  >
                                    ADD TO CART
                                  </button>
                                )}
                              </>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              }}
            />
          </Form>
        )}
      </Formik>
    </>
  );
};
export default AddFieldForm;

import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import SSRProvider from "react-bootstrap/SSRProvider";
import "./assets/css/style.css";
import RouteIndex from "./routes";
function App() {
  return (
    <SSRProvider>
      <RouteIndex />
    </SSRProvider>
  );
}

export default App;

import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useSearchParams } from "react-router-dom";
import Breadcrumb from "../../components/breadcrumb";
import { CartCard } from "../../components/cards/cart-card";
import { OrderDetailCard } from "../../components/cards/order-detail-card";
import { Norecord } from "../../components/norecord";
import { RootState } from "../../store/root-reducer";
import { orderdetailAPI } from "../../store/screens/order";
import { useOrderDetail } from "./components/order-detail-hooks";
import "../../assets/css/order_details.css";
import ToastNotification from "../../components/toast-notification";
const OrderDetail = () => {
  const {
    orderData,
    finalWeight,
    date,
    setReviewState,
    callAddReviewAPI,
    show,
    setShow,
    showErr,
    setShowErr,
    handleOrderConfirmStatusUpdate,
    callUpdateSalesOrderStatusAPI,
    reviewState,
    handleReorder,
    showReorder,
    setShowReorder,
  } = useOrderDetail();
  const param = useParams();

  const [searchParams] = useSearchParams();
  const getOrderStatusValueFromURL = searchParams.get("status");
  const grandWeight = orderData.total_grand_weight;
  const common_comment = orderData.common_comment;
  const customerName = orderData.cust_name;
  // console.log(param);
  // console.log(orderData);
  const printPage = (e: any) => {
    window.print();
  };
  let totalWeight: any = 0;
  let granttotalWeight: any = 0;

  const getProductOrderStatus = (orders?: any) => {
    const hasAcceptedStatus = orders?.some(
      (order: any) => order?.status === "Accepted"
    );
    if (hasAcceptedStatus) {
      return false;
    }
    const hasConfirmedStatus = orders?.some(
      (order: any) => order?.status === "Confirmed"
    );
    if (hasConfirmedStatus) {
      return true;
    }
    return false;
    // return hasConfirmedStatus;
  };

  const toShowDispatchBtn = (orders: any, sales_order_id: any) => {
    if (
      getOrderStatusValueFromURL === "Completed" ||
      getOrderStatusValueFromURL === "Cancelled"
    ) {
      return null;
    } else {
      const booleanValueDoesButtonNeedsToBeShown =
        getProductOrderStatus(orders);

      if (booleanValueDoesButtonNeedsToBeShown) {
        return (
          <div className="col-4 text-end">
            {/* <button
              className=" ps-1 pe-1 mt-3"
              onClick={() => {
                handleDispatch(param.orderId, grandWeight);
                console.log("details page dispatch");
              }}
            >
              Cancel Orders
            </button> */}

            <button
              className=" ps-1 pe-1 mt-3 me-3"
              style={{
                width: "120px",
                backgroundColor: "#08610f",
                color: "white",
              }}
              onClick={() => {
                handleOrderConfirmStatusUpdate(sales_order_id);
                console.log("details page dispatch");
              }}
            >
              Order Confirm
            </button>
          </div>
        );
      }
    }
  };
  return (
    <div className="print-preview">
      <ToastNotification
        setShow={setShow}
        show={show}
        content="Review Added Successfully!"
      />
      <ToastNotification
        setShow={setShowErr}
        show={showErr}
        content="Something went wrong while adding your review"
      />
      <ToastNotification
        setShow={setShowReorder}
        show={showReorder}
        content="Reorder unsuccessful as your Cart is not empty"
      />
      <div className="breadcrumb-sec">
        <div className="container-lg">
          <div className="row">
            <div className="col-12">
              <Breadcrumb
                breadcrumbs={[
                  { to: "/", name: "Home" },
                  { to: "/order-list", name: "Order List" },
                  { to: "/order-detail/" + param.orderId, name: param.orderId },
                ]}
              />
            </div>
          </div>
        </div>
      </div>
      {orderData
        ? orderData.data && (
            <div>
              <div className="container mt-5 mb-3" id="section-to-print">
                <div className="order-heading text-center content-prev">
                  <h2>Order</h2>
                </div>
                <div className="row">
                  <div className="col-6 p-0">
                    <div className="order-block">
                      <p className="cust-name">
                        Customer Name : {customerName}
                      </p>
                    </div>
                  </div>
                  <div className="col-6 text-end">
                    <div className="d-flex">
                      <div className="col-11 btn-product-card">
                        <button
                          className="btn"
                          onClick={() => handleReorder(customerName)}
                        >
                          Reorder
                        </button>
                      </div>
                      <div className="col-1  print-order mt-2">
                        <i
                          className="fa fa-print"
                          aria-hidden="true"
                          onClick={(e: any) => printPage(e)}
                        ></i>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="content-prev">
                {orderData.data.map((item: any) => (
                  <div
                    className="container m-top content-prev"
                    style={{
                      border: "1px solid #ECF0F1",
                      marginTop: "5px",
                      pageBreakBefore: "always",
                    }}
                  >
                    {/* <div className="row black border content-prev">
                      <div className="col-8 border-bottom border-top p-0 col-bg">
                        <div className="row">
                          <div className="col-6 border-end text-center">
                            Products
                          </div>
                          <div className="col-2 border-end text-center ">
                            Purity
                          </div>
                          <div className="col-4  text-start">Note</div>
                        </div>
                      </div>
                      <div className="col-4 black border-top border-start border-bottom p-0 col-bg"></div>
                    </div> */}
                    <h2
                      className="pt-2"
                      style={{ marginTop: "", paddingLeft: "10px" }}
                    >
                      {item.level_2_category} | Total Weight :{" "}
                      {item.level_2_total_weight.toFixed(2)}
                    </h2>
                    <h2></h2>
                    <div className="row ">
                      <div className="col-12 ">
                        {/* <div className="order-block pt-2 pb-2">
                        <p>Customer Name : {customerName}</p>
                        <p>Order Date: {item.transaction_date}</p>
                        <p>Order Id: {param.orderId}</p>
                      </div>
                      <div className="row black border content-prev" style={{width:'calc(100% + 23px)'}}>
                        <div className="col-8 border-bottom border-top p-0 col-bg">
                          <div className="row">
                            <div className="col-6 border-end text-center">
                              Products
                            </div>
                            <div className="col-1 border-end text-center ">
                              Purity
                            </div>
                            <div className="col-3  text-start">Note</div>
                            <div className="col-1  text-start">Status</div>
                          </div>
                        </div>
                        <div className="col-4 black border-top border-start border-bottom p-0 col-bg"></div>
                      </div> */}

                        <div className="row">
                          <div className="col-12">
                            <div className="row">
                              <div className="col-8">
                                <div className="order-block pt-2 pb-2">
                                  <p>Customer Name : {customerName}</p>
                                  <p>Order Date: {item.transaction_date}</p>
                                  <p>Order Id: {param.orderId}</p>
                                  {/* <p>
                                    {showDispatchWeight(
                                      orderData?.dispatch_weight
                                    )}

                                    
                                  </p> */}
                                </div>
                              </div>

                              {toShowDispatchBtn(item.orders, param.orderId)}

                              {/* {getOrderStatusValueFromURL === null && (
                                  <div className="col-4 text-end">
                                    <button
                                      className=" ps-1 pe-1 mt-3"
                                      onClick={() => {
                                        handleDispatch(
                                          param.orderId,
                                          grandWeight
                                        );
                                        console.log("details page dispatch");
                                      }}
                                    >
                                      Order Confirmed
                                    </button>
                                  </div>
                                )} */}
                            </div>
                          </div>
                          <div className="col-12">
                            <div
                              className="row black border content-prev"
                              style={{ width: "calc(100% + 23px)" }}
                            >
                              <div className="col-7 border-bottom border-top p-0 col-bg">
                                <div className="row">
                                  <div className="col-6 border-end text-center">
                                    Products
                                  </div>
                                  <div className="col-1 border-end text-center ">
                                    Purity
                                  </div>
                                  <div className="col-3  text-start">Note</div>
                                  <div className="col-1  text-start">
                                    Status
                                  </div>
                                </div>
                              </div>
                              <div className="col-5 black border-top border-start border-bottom border-end p-0 col-bg"></div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {item.orders.map((ord: any, index: number) => {
                      return (
                        <OrderDetailCard
                          key={index + ord?.item_code}
                          name={ord?.item_code}
                          barcodeimage={ord?.bar_code_image}
                          bom_factory_code={ord?.bom_factory_code}
                          level_2_category={ord?.level_2_category}
                          net_weight={ord?.net_weight}
                          market_design_name={ord?.market_design_name}
                          image={ord?.image}
                          order={ord?.order}
                          weight={ord?.weight_abbr}
                          purity={ord?.purity}
                          remark={ord?.remark}
                          wastage={ord?.wastage}
                          totalWeight={ord?.total_weight}
                          status={ord.item_status}
                          setReviewState={setReviewState}
                          callAddReviewAPI={callAddReviewAPI}
                          customerName={customerName}
                          order_id={param?.orderId}
                          order_date={item?.transaction_date}
                          issue_weight={ord?.issue_weight}
                          review_value={ord?.review}
                          getOrderStatusValueFromURL={
                            getOrderStatusValueFromURL
                          }
                          soisd_item={ord?.soisd_item}
                          callUpdateSalesOrderStatusAPI={
                            callUpdateSalesOrderStatusAPI
                          }
                          reviewState={reviewState}
                        />
                      );
                    })}
                  </div>
                ))}
              </div>
            </div>
          )
        : null}
      <div className="container mb-4 content-prev ">
        <div className="row border">
          <div className="col-6 text-start p-2">
            <h5 className="mb-0 mt-2 ps-1">
              Grand Total Weight: {grandWeight}gm
            </h5>
          </div>
          <div className="col-6 text-end">
            <h5 className="mb-0 mt-2 ps-1">{common_comment}</h5>
          </div>
        </div>
      </div>
    </div>
  );
};
export default OrderDetail;

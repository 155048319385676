import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import api from "../../services/api/subcategory-api";
import {handleResponse} from "../../services/config/handle-response";

interface RepoSubcategoryState {
  subcategory: any;
  error: any;
  loader:boolean;
}

const initialState: RepoSubcategoryState = {
  subcategory: [],
  error: "",
  loader: false,
};

const subcategoryScreen = createSlice({
  name: "subcategory",
  initialState,
  reducers: {
    categorySuccess(state, action: PayloadAction<RepoSubcategoryState>) {
      state.subcategory = action.payload;
      state.error = '';
      state.loader = false;
    },
    categoryFailed(state) {
      state.error = 'Error or Occured';
      state.loader = false;
    },
    isLoading(state) {
      state.loader = true;
    },
  },
});

const {categorySuccess, categoryFailed, isLoading} = subcategoryScreen.actions;

export const subcategoryAPI =
  (categoryId :any ): any =>
  async (dispatch: any) => {
    try {
      dispatch(isLoading());
      const response = await api.getSubcategoriesListAPI(categoryId);
      const result = dispatch(handleResponse(response));
      if (result) {
        dispatch(categorySuccess(result));
      } else {
        dispatch(categoryFailed());
      }
    } catch (err) {}
  };



export default subcategoryScreen.reducer;

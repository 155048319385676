import React from "react";
import { Link } from "react-router-dom";
import norecord from "./../assets/img/norecordfound.png";

export const Norecord = (props:any) => {
  const {heading, content, img} = props;
  return (
    // <div style={{
    //           display: "flex",
    //           justifyContent: "center",
    //           alignItems: "center",
    //           marginTop:"30px",
    //         }}>
    //   <img src={norecord} alt="" style={{width:400}}/>
    // </div>

    <div className="container text-center mt-4">
      <h1>{heading}</h1>
      <div className="row justify-content-center">
        <div className="col-lg-12">
          <img src={img} alt="" />
          <p>{content}</p>
          <Link to="/" className="btn btn-primary rounded-pill my-3 py-3 px-5">
            Go Back To Home
          </Link>
        </div>
      </div>
    </div>
  );
};

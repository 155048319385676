import axios from "axios";
import { DEFAULT_API_CONFIG } from "../config/api-config";

export const GETReviewListingAPI = async () => {
  let response: any;

  let login = localStorage.getItem("token");
  let user: any = localStorage.getItem("user"); // Retrieve the user from localStorage

  if (user !== null) {
    user = JSON.parse(user);
  } else {
    return;
  }
  const url = `${DEFAULT_API_CONFIG?.url}api/method/digitalcatalog_api_erpnext.api.sales_order_reports.review_dispatched_orders_list?user=${user}`;

  const config = {
    headers: {
      Authorization: login && JSON.parse(login)?.access_token,
    },
  };

  await axios
    .get(`${url}`, config)
    .then((res: any) => {
      console.log("get review list api success", res);
      response = res;
    })
    .catch((err: any) => {
      console.log("get review list api err", err);
      response = err;
    });

  return response;
};

export const GETReviewDetailAPI = async (sales_order_id: any) => {
  let response: any;

  const params = `?name=${sales_order_id}`;

  let login = localStorage.getItem("token");
  const url = `${DEFAULT_API_CONFIG?.url}/api/method/digitalcatalog_api_erpnext.api.sales_order_review.get_review_details`;

  const config = {
    headers: {
      Authorization: login && JSON.parse(login)?.access_token,
    },
  };

  await axios
    .get(`${url}${params}`, config)
    .then((res: any) => {
      console.log("get review list api success", res);
      response = res;
    })
    .catch((err: any) => {
      console.log("get review list api err", err);
      response = err;
    });

  return response;
};

// import { dispatchOrderAPI } from "../../store/screens/order";
import {callAPI} from "../config/api-core";
import { OrderAPIMethods } from "../methods/order-api-methods";


const orderFetch = async (request:any): Promise<any> => {
  let login =  localStorage.getItem('token');
  let header = {
    Authorization: login && JSON.parse(login)?.access_token,
  };
  const response = await callAPI(
    OrderAPIMethods.orderList,
    true,
    request,
    header,
    false,  
    false
  );
  return response;
};

const placeOrderFetch = async (request:any): Promise<any> => {
  let login =  localStorage.getItem('token');
  let header = {
    Authorization: login && JSON.parse(login)?.access_token,
  };
  // let params = `&level_2_category=${param1}&filters=${"{"+filterData+"}"}`
  const response = await callAPI(
    OrderAPIMethods.placeOrderList,
    true,
    request,
    header,
    false,
    false
  );
  return response;
};

const orderDetailFetch = async (request:any): Promise<any> => {
  let login =  localStorage.getItem('token');
  let header = {
    Authorization: login && JSON.parse(login)?.access_token,
  };
  const response = await callAPI(
    OrderAPIMethods.orderDetailList,
    true,
    request,
    header,
    false,
    false
  );
  return response;
};

const dispatchOrderAPI = async (request:any):Promise<any> =>
{
  let login =  localStorage.getItem('token');
  let header = {
    Authorization: login && JSON.parse(login)?.access_token,
  };
  const response = await callAPI(
    OrderAPIMethods.dispatchOrder,
    true,
    request,
    header,
    false,
    false
  );
  return response;
}

  const getOrderListAPI = (request:any) => orderFetch(request);
  const getPlaceOrderListAPI = (request:any) => placeOrderFetch(request);
  const getOrderDetailListAPI = (request:any) => orderDetailFetch(request);
  const postDispatchOrder= (request:any)=>dispatchOrderAPI(request);

  export default {
    getOrderListAPI,
    getPlaceOrderListAPI,
    getOrderDetailListAPI,
    postDispatchOrder
  };
  
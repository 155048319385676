import PopupModal from "../popup-modal";
import { usePurity } from "./purity-hooks";
import FormSelect from "react-bootstrap/Form";
import { useState } from "react";

interface Props {
  funcColor: (col: string) => void;
  handleSortBySeq: (seqBooleanValue: string) => void;
  sortBySeq: string;
  handlesortByDesc: (descBooleanValue: boolean) => void;
  sortByDesc: boolean;
}

const Purity = ({
  funcColor,
  handleSortBySeq,
  sortBySeq,
  handlesortByDesc,
  sortByDesc,
}: Props) => {
  const getPathName = window.location.pathname;
  const { purity, defaultPurity, isOpen, handlePurity, clearCart, hideModal } =
    usePurity();

  const getSelectedColor: any = localStorage.getItem("selectedColor");

  const [value, setValue] = useState<any>(
    getSelectedColor !== null ? getSelectedColor : "Yellow"
  );

  const [customername, setcustomername] = useState("");

  const handleSelect = (color: any) => {
    funcColor(color);
    setValue(color);
    localStorage.setItem("selectedColor", color);
  };

  const handleNameSave = () => {
    localStorage.setItem("customer-name", customername);
  };

  return (
    <>
      <div className="container purity-cont">
        <div
          className={
            getPathName === "/quick-order" ? "col-lg-4 col-12 mx-auto" : ""
          }
        >
          <div className="row">
            <div className="col-md-2">
              <div className="row justify-content-center">
                <div className="col-12 p-0">
                  {getPathName !== "/quick-order" && (
                    <div className="name-field">
                      <div className="input-group mb-3 mt-0">
                        <input
                          type="text"
                          className="form-control custom-save"
                          placeholder="Enter Name"
                          aria-label="Recipient's username"
                          aria-describedby="button-addon2"
                          onChange={(e: any) => setcustomername(e.target.value)}
                        />
                        <button
                          className="btn btn-outline-secondary btn-save"
                          type="button"
                          id="button-addon2"
                          onClick={handleNameSave}
                        >
                          Save
                        </button>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="select-purity text-center">
                {getPathName !== "/quick-order" && (
                  <p className="text">Select Order Purity:</p>
                )}
                {purity.length > 0 &&
                  purity.map((data: any, index: any) => {
                    return (
                      <button
                        type="button"
                        className={`btn ms-4 my-lg-0 my-2 btn-purity ${
                          data.name === defaultPurity ? "active" : ""
                        }`}
                        onClick={(e) => handlePurity(e, data.name)}
                      >
                        {data.name}
                      </button>
                    );
                  })}
              </div>
              <div>
                {isOpen && (
                  <PopupModal
                    show={isOpen}
                    onHide={hideModal}
                    purity={defaultPurity}
                    clearCart={clearCart}
                  />
                )}
              </div>
            </div>
            <div className="col-md-4 text-center">
              {getPathName !== "/quick-order" && (
                <div className="d-flex justify-content-around ">
                  <div>
                    <FormSelect.Select
                      value={value}
                      onChange={(e: any) => handleSelect(e.target.value)}
                      className="select-form color-select"
                    >
                      <option value="Yellow">Yellow</option>
                      <option value="Rose">Rose</option>
                      <option value="White">White</option>
                    </FormSelect.Select>
                  </div>

                  <div>
                    <input
                      type="checkbox"
                      name="sequence"
                      onChange={(e: any) => handleSortBySeq(e.target.checked)}
                    />
                    {getPathName !== "/quick-order" && (
                      <label className="ms-1 sequence-wrapper">
                        Sort By - Sequence{" "}
                      </label>
                    )}
                    <div style={{ width: "150px" }}>
                      <FormSelect.Select
                        value={sortBySeq}
                        onChange={(e: any) => handleSortBySeq(e.target.value)}
                        className="sort-select"
                      >
                        <option value="creation" selected>
                          Created On{" "}
                        </option>
                        <option value="sequence">Sequence</option>
                        <option value="weight_range">Weight Range</option>
                      </FormSelect.Select>
                    </div>
                    <div className="text-start">
                      <input
                        checked={sortByDesc}
                        type="checkbox"
                        name="sequence"
                        onChange={(e: any) =>
                          handlesortByDesc(e.target.checked)
                        }
                      />
                      {getPathName !== "/quick-order" && (
                        <label className="ms-2">Descending</label>
                      )}
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Purity;

import React from "react";
import {Link} from "react-router-dom";
import img from "../../assets/img/cart1.png"

const NoPage=()=>{
  return(
    <>
    <div className="container-xxl py-5 wow fadeInUp" data-wow-delay="0.1s">
    <div className="container text-center">
        <div className="row justify-content-center">
            <div className="col-lg-6">
                <i className="bi bi-exclamation-triangle display-1 text-primary"></i>
                <img src={img} alt="" />
                <h1 className="mb-4">Page Not Found</h1>
                <p className="mb-4">We’re sorry, the page you have looked for does not exist in our website! Maybe go to our home page or try to use a search?</p>
                <Link to="/" className="btn btn-primary rounded-pill py-3 px-5" >Go Back To Home</Link>
            </div>
        </div>
    </div>
</div>
</>
/* <>
      <div className="container text-center">
        <h1>My Cart</h1>
        <div className="row justify-content-center">
          <div className="col-lg-12">
            <img src={img} alt=""/>
            <p>Your cart seems light! Click below to add products of your choice.
            </p>
            <p>Hey, why not add products that you love and make yourself happy?!</p>

            <Link to="/" className="btn btn-primary rounded-pill my-3 py-3 px-5">
              Go Back To Home
            </Link>
          </div>
        </div>
      </div>
</> */
  )
}
export default NoPage
import React from "react";

const ReportTotalRow = ({ calculationRowData }: any) => {
  return (
    <>
      <tr>
        <td colSpan={1}>
          <b>Total</b>
        </td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td style={{ fontWeight: "bold" }}>
          {calculationRowData?.qty?.toFixed(3)}
        </td>
        <td style={{ fontWeight: "bold" }}>
          {calculationRowData?.total_wt?.toFixed(3)}
        </td>
        <td style={{ fontWeight: "bold" }}>
          {calculationRowData?.total_net_wt?.toFixed(3)}
        </td>
        <td></td>
      </tr>
    </>
  );
};

export default ReportTotalRow;

import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { RootState } from '../store/root-reducer';
import { PFRouteProps } from "./protectedRouteProps.props";

export const PublicRoute: React.FC<PFRouteProps> = ({children}) => {
  let user = false;
  const login = useSelector((state: RootState) => state.login);
  let userFromLocalStorage  =  localStorage.getItem('token');
  if(login.user && login.user.access_token){
    user = true;
  }else if(userFromLocalStorage && JSON.parse(userFromLocalStorage).access_token){
    user = true;
  }
  if (user) {
    return <Navigate to="/categories" replace />;
  }
  return children;
};
export default PublicRoute;
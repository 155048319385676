import React, { useState, useEffect } from "react";
import { Button, Modal, ModalHeader } from "react-bootstrap";
import "../assets/css/addField.css";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { PostCartAPI, cartAPI } from "../store/screens/cart";
import { Formik, Field, Form, FieldArray, ErrorMessage } from "formik";
import * as Yup from "yup";
import { productdetailAPI } from "../store/screens/products";
import { RootState } from "../store/root-reducer";
import AddFieldForm from "./addFieldForm";
import "../assets/css/addField.css";
import axios from "axios";
import { DEFAULT_API_CONFIG } from "../services/config/api-config";

interface props {
  title: string;
  show: any;
  onHide: any;
  purity?: any;
  image?: any;
  weight?: any;
  size?: any;
  setRev?: any;
  color?: any;
  prodCodes?: any;
  bom_factory_code?: any;
  level_2_category?: any;
  market_design_name?: any;
  net_weight?: any;
  hasBroadness?: any;
  storeLength?: any;
  factory_name?: any;
  custom_size?: any;
}

const AddModal = ({
  title,
  show,
  onHide,
  purity,
  image,
  weight,
  size,
  setRev,
  color,
  prodCodes,
  bom_factory_code,
  level_2_category,
  market_design_name,
  net_weight,
  hasBroadness,
  storeLength,
  factory_name,
  custom_size,
}: props) => {
  let [prodvariants, setprodvariants] = useState<any>([]);
  let [variantTitle, setvariantTitle] = useState("");
  let [weightperunit, setweightperunit] = useState("");
  const param = useParams();
  // console.log(weight)
  // console.log(title)
  // console.log(image);
  const dispatch = useDispatch();
  const user = localStorage.getItem("user");
  let users = user ? JSON.parse(user) : null;

  let params = {
    item_code: title,
    fields: "*",
    user: users,
  };
  useEffect(() => {
    dispatch(productdetailAPI(params));
  }, []);
  const productdetail: any = useSelector(
    (state: RootState) => state.products.productDetail
  );
  // console.log(productdetail);
  // console.log(productdetail.image);
  // console.log(productdetail.variant_of);
  const getSelectedProductWeight = param.productId?.split("-");

  useEffect(() => {
    if (productdetail.variant_of) {
      const login = localStorage.getItem("token");
      const config = {
        headers: {
          Authorization: login && JSON.parse(login)?.access_token,
        },
      };
      const getVariants = async (variant: any) => {
        // console.log(variant);
        await axios
          .post(
            `${DEFAULT_API_CONFIG.url}/api/method/digitalcatalog_api_erpnext.api.product_detail.get_product_detail`,
            { item_code: `${variant}`, fields: "*" },
            config
          )
          .then((res) => {
            console.log("get variants response", res);
            setprodvariants(
              (prodvariants = [
                ...res.data.message.variants.sort(function (a: any, b: any) {
                  return a.Weight - b.Weight;
                }),
              ])
            );
          })
          .catch((err) => console.log(err));
      };
      getVariants(productdetail.variant_of);
    } else {
      console.log("inside useeffect hook but condition not applied");
    }
  }, [productdetail]);

  const handleShowVariant = async (code: any) => {
    // console.log(code);
    const login = localStorage.getItem("token");
    const config = {
      headers: {
        Authorization: login && JSON.parse(login)?.access_token,
      },
    };

    await axios
      .post(
        `${DEFAULT_API_CONFIG.url}/api/method/digitalcatalog_api_erpnext.api.product_detail.get_product_detail`,
        { item_code: `${code}`, fields: ["item_code", "weight_per_unit"] },
        config
      )
      .then((res) => {
        console.log("after click new response", res);
        setvariantTitle(res.data.message.ite);
      })
      .catch((err) => console.log(err));
  };

  // console.log("check for variants weight", weight);
  return (
    <>
      <div className="container">
        <div className="row">
          <div className="col-12">
            <Modal
              show={show}
              onHide={onHide}
              purity
              contentClassName="modal-content-sm"
            >
              <ModalHeader closeButton></ModalHeader>
              <Modal.Body>
                <>
                  {/* <div>
                {prodvariants &&
                          prodvariants.map((item: any) => {
                            return getSelectedProductWeight?.[2] ===
                              item.Weight ? (
                              <button

                                className="btn btn-cart ms-2"
                                style={{
                                  height: "48px",
                                  color: "white",
                                  backgroundColor: "rgb(3,15,39)",
                                }}
                              >
                                <span>{item.Category} </span> <br />
                                <span>{item.Weight}</span>
                              </button>
                            ) : (
                              <button
                              onClick={()=>handleShowVariant(item.item_code)}
                              className="btn btn-cart ms-2"
                              style={{ height: "48px" }}
                              >
                                <span>{item.Category} </span> <br />
                                <span>{item.Weight}</span>
                                <span>{item.item_code}</span>
                              </button>
                            );
                          })}
                </div> */}
                  <div>
                    <div className="addformfield">
                      <AddFieldForm
                        title={title}
                        onHide={onHide}
                        weight={weight}
                        outer_size={size}
                        setRev={setRev}
                        color={color}
                        prodCodes={prodCodes}
                        bom_factory_code={bom_factory_code}
                        custom_size={custom_size}
                        factory_name={factory_name}
                        level_2_category={level_2_category}
                        net_weight={net_weight}
                        market_design_name={market_design_name}
                        hasBroadness={hasBroadness}
                        storeLength={storeLength}
                      />
                    </div>
                    <div className="product_images text-center">
                      {productdetail && (
                        <img
                          src={`${DEFAULT_API_CONFIG.url}${image}`}
                          className="img-fluid"
                        />
                      )}
                    </div>
                  </div>
                </>
              </Modal.Body>
            </Modal>
          </div>
        </div>
      </div>
    </>
  );
};
export default AddModal;

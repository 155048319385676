// import {configureStore, Action} from "@reduxjs/toolkit";
// import thunk, {ThunkAction} from "redux-thunk";
// import rootReducer, {RootState} from "./root-reducer";
// import {persistStore, persistReducer} from "redux-persist";
// import storage from 'redux-persist/lib/storage' // defaults to localStorage for web

// const persistConfig = {
//   key: "root",
//   storage: localStorage,
//   whitelist: ["login"],
// };
// const persistedReducer = persistReducer(persistConfig, rootReducer);

// const store = configureStore({
//   reducer: rootReducer,
//   middleware: [thunk],
  
// });
// let persistor = persistStore(store);
// export type AppDispatch = typeof store.dispatch;

// export type AppThunk = ThunkAction<void, RootState, null, Action<string>>;

// export {persistor}
// export {store};

import { applyMiddleware, legacy_createStore as createStore} from 'redux'
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage' // defaults to localStorage for web
 import ReduxThunk from "redux-thunk";
import rootReducer from './root-reducer'
const persistConfig = {
  key: 'root',
  storage,
}
const persistedReducer = persistReducer(persistConfig, rootReducer);
let store = createStore(persistedReducer,applyMiddleware(ReduxThunk));
let persistor = persistStore(store);
export default store
export {persistor}
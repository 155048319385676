import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { RootState } from "../../../store/root-reducer";
import { subcategoryAPI } from "../../../store/screens/subcategory";

export const useSubcategory = () => {
  const dispatch = useDispatch();
  const subcategory = useSelector((state: RootState) => state.subcategories);
  const [subcategories, setSubCategories] = useState([]);
  const params = useParams();
  // useEffect(() => {
  //   console.log(params);
  //   if(params.categoryId)
  //     dispatch(subcategoryAPI(params.categoryId));
  // }, []);
  useEffect(() => {
    console.log(params);
    if(params.categoryId)
      dispatch(subcategoryAPI(params.categoryId));
  }, [params.categoryId]);

  useEffect(()=>{
    setSubCategories(subcategory.subcategory);
  },[subcategory])

  return {
    subcategories
  };
};

import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

interface props {
  paginate: any;
  totalRecord: any;
  currentPage?: any;
  handleNextbtn?: any;
  handlePrevbtn?: any;
  maxPageNumberLimit?: any;
  minPageNumberLimit?: any;
  productPage?: any;
}

const Pagination = ({
  paginate,
  totalRecord,
  currentPage,
  handleNextbtn,
  handlePrevbtn,
  maxPageNumberLimit,
  minPageNumberLimit,
  productPage,
}: props) => {
  const [pageNumbers, setPageNumber] = useState([]);
  let limit = 20;

  console.log(totalRecord);

  useEffect(() => {
    console.log("sssss");
    setPageNumbers();
  }, [Math.ceil(totalRecord / limit)]);

  const setPageNumbers = () => {
    let pageNumber: any = [];
    let x = 0;
    for (let i = 1; i <= Math.ceil(totalRecord / limit); i++) {
      pageNumber[x] = i;
      setPageNumber(pageNumber);
      x++;
    }
  };
  console.log(pageNumbers);
  console.log("page", currentPage);

  return (
    <div className="container">
      <div className="row">
        <div className="col-12 d-flex justify-content-center">
          <div className="">
            {pageNumbers && pageNumbers.length > 0 ? (
              <ul className="d-flex align-items-center justify-content-center">
                {currentPage && currentPage > 1 ? (

                  <button

                    // className="btn btn-lg"
                    onClick={() => handlePrevbtn(productPage)}
                    className="" style={{ height: "38px", width: "44px" }}
                  // className={`page-link ${currentPage ? "active" : ""}`}
                  // disabled={currentPage == pageNumbers[0] ? true : false}
                  >
                    Prev
                  </button>

                ) : (
                  ""
                )}
                <div>
                  {pageNumbers.length > 1 &&
                    pageNumbers.map((number, index: any) => {
                      // console.log(number, minPageNumberLimit);
                      if (
                        number < maxPageNumberLimit + 1 &&
                        number > minPageNumberLimit
                      ) {
                        // console.log(number, maxPageNumberLimit);
                        return (

                          // <li key={index} className="page-item">
                          <button
                            style={{ height: "38px", width: "38px" }}
                            onClick={() => paginate(number, productPage)}
                            className={` ${currentPage === number ? "btn btn-dark " : null
                              }`}
                          >
                            {number}
                          </button>
                          // </li>
                        );
                      } else {
                        return null;
                      }
                    })}
                </div>
                <div>
                  {currentPage &&
                    currentPage >= 3 &&
                    currentPage !== pageNumbers[pageNumbers.length - 1] ? (

                    <button

                      // className="btn btn-lg"
                      className="" style={{ height: "38px", width: "44px" }}
                      // className={`page-link ${currentPage ? "active" : ""}`}
                      onClick={() => handleNextbtn(productPage)}
                    // disabled={currentPage == pageNumbers[pageNumbers.length - 1] ? true : false}
                    >
                      Next
                    </button>

                  ) : (
                    ""
                  )}
                </div>
              </ul>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
export default Pagination;

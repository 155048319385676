import React, { FunctionComponent as Component, useState } from "react";
import "../../assets/css/whishlist.css";
import noimage from "./../../assets/img/nia.jpg";
import { CardInterface } from "./category-interface";
// import { CONSTANT } from "../../utils/constant";
import { DEFAULT_API_CONFIG } from "../../services/config/api-config";
import { useDispatch } from "react-redux";
import { DeleteWishlistAPI, wishlistAPI } from "../../store/screens/wishlist";
import { Link, useParams } from "react-router-dom";
import ToastNotification from "../toast-notification";

export const WhishListCard: Component<CardInterface> = (props: any) => {
  const { name, image, itemGroup, setShow } = props;
  const dispatch = useDispatch();
  const Param = useParams();
  // const [show, setShow] = useState(false);

  let user = localStorage.getItem("user");
  let users = user ? JSON.parse(user) : null;

  let param = {
    user: users,
  };

  let params = {
    user: users,
    item_code: name,
  };

  const handleRemove = async (name: any) => {
    await dispatch(DeleteWishlistAPI(params));
    setShow(true);
    await dispatch(wishlistAPI(param));
    
  };

  return (
    <>
    {/* <div className="container">
      <div className="row">
    <ToastNotification
        setShow={setShow}
        show={show}
        content="Product Remove From Wishlist!"
      />
      </div>
    </div> */}
      
         <div className="col-12 border-bottom">
        <div className="row align-items-center justify-content-center">
          <div className="col-sm-4 text-center">
            {/* <Link to={"/product-detail/" + name}> */}
            <Link
              to={
                "/product-detail/" +
                Param.categoryId +
                "/" +
                Param.subCategoryId +
                "/" +
                name
              }
            >
              <img
                src={image ? DEFAULT_API_CONFIG.url + image : noimage}
                alt=""
                className="img-fluid ms-1"
                width={150}
              />
            </Link>
          </div>
          <div className="col-sm-4 text-center wishlist-content">
            {/* <Link to={"/product-detail/" + name}> */}
            <Link
              to={
                "/product-detail/" +
                Param.categoryId +
                "/" +
                Param.subCategoryId +
                "/" +
                name
              }
            >
              <h3 className="pb-2">Product code: {name}</h3>
              <h3>Product Group: {itemGroup}</h3>
            </Link>
          </div>
          <div className="col-sm-4 text-center buttons">
            <button
              className="btn-close mt-2"
              onClick={() => handleRemove(name)}
            >
              {/* <i className="fa fa-close" /> */}
            </button>
          </div>
        </div>
        {/* <hr style={{ marginRight: "35px", marginLeft: "35px" }} /> */}
        </div>
    </>
  );
};

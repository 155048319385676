import axios from "axios";
import { DEFAULT_API_CONFIG } from "../config/api-config";

const updateSalesOrderStatusAPI = async (request: any) => {
  let response: any;

  let login = localStorage.getItem("token");
  const url = `${DEFAULT_API_CONFIG?.url}/api/resource/Sales Order Item Status Details/`;

  const config = {
    headers: {
      Authorization: login && JSON.parse(login)?.access_token,
    },
  };

  const body = {
    customer_status: "Completed",
    manufacturing_status: "Completed",
    customer_review: request.customer_review,
  };

  await axios
    .put(`${url}${request.id}`, body, config)
    .then((res: any) => {
      response = res;
    })
    .catch((err: any) => {
      console.log("add review api err", err);
      response = err;
    });

  return response;
};

export default updateSalesOrderStatusAPI;

import { useState } from "react";
import { useParams } from "react-router-dom";
import Breadcrumb from "../../components/breadcrumb";
import { ProductCard } from "../../components/cards/product-card";
import { Norecord } from "../../components/norecord";
import Pagination from "../../components/pagination";
import Sidebar from "../../components/product-sidebar";
import Purity from "../../components/purity";
import { useProducts } from "../products/components/product-hooks";
import { useAllProducts } from "./components/all-products-hooks";

const AllProduct = () => {
  const {
    handleCheckboxChange,
    handleSortBySeq,
    handleFilter,
    checked,
    paginate,
    curretPage,
    handleNextbtn,
    handlePrevbtn,
    maxPageNumberLimit,
    minPageNumberLimit,
    arrayOfCodes,
    sortBySeq,
    handlesortByDesc,
    sortByDesc
  } = useProducts();

  const param = useParams();
  const { totalCount, allProducts, allproducts } = useAllProducts();
  console.log(checked);
  const [showSidebar, setshowSidebar] = useState(true);
  const [colorValue, setcolorValue] = useState("Yellow");

  const hideSidebar = () => {
    setshowSidebar(!showSidebar);
  }

  const handleColor = (col: string) => {
    console.log(col);
    setcolorValue(col);
  }

  return (
    <>
      <div className="breadcrumb-sec">
        <div className="container-lg">
          <div className="row">
            <div className="col-12">
              <Breadcrumb
                breadcrumbs={[
                  { to: "/categories", name: param.categoryId },
                  {
                    to: "/all-product/" + param.categoryId,
                    name: "All Products",
                  },
                ]}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="row px-xl-5 pb-3">
        <div></div>
        <div id="body">
          <div className="container-fluid">
            <Purity funcColor={handleColor} handleSortBySeq={handleSortBySeq} sortBySeq={sortBySeq} handlesortByDesc={handlesortByDesc} sortByDesc={sortByDesc} />
            <div className="col-12">
              <div className="filter-button ms-5">
                <button onClick={hideSidebar} className="p-1">Show/Hide Filters </button>
              </div>
            </div>
            <div className="products-wrap ps-lg-5 pe-lg-4">
              {showSidebar ? (
                <div className="sidebar_hide" >

                  <Sidebar
                    handleCheckboxChange={(
                      e: any,
                      index: any,
                      type: any,
                      productPage: any
                    ) => handleCheckboxChange(e, index, type, productPage)}
                    // handleCheckboxChange={(e: any, index: any, type: any) => handleCheckboxChange(e, index, type)}
                    handleFilter={handleFilter}
                    productData={allproducts}

                    checked={checked}
                  />
                </div>
              ) : null}
              {/* <Sidebar
                handleCheckboxChange={(e: any, index: any, type: any) =>
                  handleCheckboxChange(e, index, type)
                }
                handleFilter={handleFilter}
                productData={allproducts}
                checked={checked}
              /> */}
              <div id="content" className={`container ${!showSidebar ? 'filter-set' : ''}`} style={{ marginTop: '7px' }}>
                <div className="products row">
                  {allProducts && allProducts.length > 0 ? (
                    allProducts.map((product: any, index: any) => {
                      let {
                        name,
                        weight_range,
                        image,
                        weight_per_unit,
                        length,
                      } = product;
                      return (
                        <ProductCard
                          key={index + name}
                          name={name}
                          image={image}
                          weightRange={weight_range}
                          weight={weight_per_unit}
                          size={length}
                          color={colorValue}
                          codesArray={arrayOfCodes}
                          redirectUrl={
                            "/product-detail/" +
                            param.categoryId +
                            "/" +
                            param.subCategoryId +
                            "/" +
                            name
                          }
                        // purity={JSON.parse(selectedpurity) }
                        />
                      );
                    })
                  ) : (
                    <Norecord />
                  )}
                </div>
              </div>
            </div>
            {allProducts && allProducts.length > 0 ? (
              <div className="row">
                <div className="col-12 d-flex justify-content-center">
                  <div className="paginations">
                    <Pagination
                      paginate={paginate}
                      totalRecord={totalCount}
                      currentPage={curretPage}
                      handlePrevbtn={handlePrevbtn}
                      handleNextbtn={handleNextbtn}
                      maxPageNumberLimit={maxPageNumberLimit}
                      minPageNumberLimit={minPageNumberLimit}
                    />
                  </div>
                </div>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    </>
  );
};
export default AllProduct;

import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../store/root-reducer";
import { orderAPI,dispatchOrderAPI } from "../../../store/screens/order";

export const useOrderList=()=>{
  const dispatch = useDispatch();
  let orderlist = useSelector((state: RootState) => state.orders);
  console.log("order list",orderlist);

  // useEffect(()=>
  // {
  // },[])


  // Below function is to change the order status from 'To Deliver and Bill' to 'Completed'
  // const handleDispatch = async(orderName:any, weight:any)=>
  // {
  //   let user = localStorage.getItem("user");
  //   let users = user ? JSON.parse(user) : null;
    
  //   // console.log("dispatch",orderName);
  //   // console.log("dispatch",weight);
  //   let userParam = {
  //     user: users,
  //   }
  //   let params = {
  //     order_id:orderName,
  //   }
  //   dispatch(dispatchOrderAPI(params));
  //   // dispatch(orderAPI(userParam));
  //   setTimeout(()=>
  //   {
  //     dispatch(orderAPI(userParam));
  //   },2000)
  // }

  useEffect(() => {
    let user = localStorage.getItem("user");
    let users = user ? JSON.parse(user) : null;
    let params = {
      user: users,
    };
    dispatch(orderAPI(params));
  }, []);
  
  useEffect(()=>
  {
    orderlist=orderlist
  },[orderlist])

  return{
    orderlist,
    // handleDispatch
  }
}
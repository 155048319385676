import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import api from "../../services/api/wishlist-api";
import {handleResponse} from "../../services/config/handle-response";
import {CONSTANT} from "../../utils/constant";

interface RepoWishlistState {
  wishlist: any;
  error: any;
}

const initialState: RepoWishlistState = {
  wishlist: [],
  error: "",
};

const wishlistScreen = createSlice({
  name: "wishlist",
  initialState,
  reducers: {
    getWishlistSuccess(state, action: PayloadAction<RepoWishlistState>) {
      // localStorage.setItem('user', JSON.stringify(action.payload));
      state.wishlist = action.payload;
      state.error = '';
    },
    wishlistFailed(state) {
      state.error = 'Error or Occured';
    },
    // deleteWishlist()
  },
});

const {getWishlistSuccess, wishlistFailed} = wishlistScreen.actions;

export const wishlistAPI =
  (request:any): any =>
  async (dispatch: any) => {
    try {
      const response = await api.getWishlistAPI(request);
      const result = dispatch(handleResponse(response));
      if (result) {
        dispatch(getWishlistSuccess(result));
      } else {
        dispatch(wishlistFailed());
      }
    } catch (err) {}
  };

export const PostWishlistAPI =
(request: any): any =>
async (dispatch: any) => {
  try {
    const response = await api.postWishlistAPI(request);
    const result = dispatch(handleResponse(response));
    
  } catch (err) {}
};

export const DeleteWishlistAPI =
(request: any): any =>
async (dispatch: any) => {
  try {
    const response = await api.deleteWishlistAPI(request);
    const result = dispatch(handleResponse(response));
  } catch (err) {}
};


export default wishlistScreen.reducer;

// import { convertCompilerOptionsFromJson } from "typescript";
import store from "../../store/store";
import { callAPI } from "../config/api-core";
import { NavitemsAPIMethods } from "../methods/navitems-api-method";

const navitemsFetch = async (): Promise<any> => {
  let login = localStorage.getItem("token");
  let header = {
    Authorization: login && JSON.parse(login)?.access_token,
  };
  const response = await callAPI(
    `${NavitemsAPIMethods.NavitemsList}`,
    true,
    {},
    header,
    false,
    false
  );
  return response;
};

const getNavitemsListAPI = () => navitemsFetch();
// eslint-disable-next-line import/no-anonymous-default-export
export default {
  getNavitemsListAPI,
};

import { callAPI } from "../config/api-core";
import { AuthApiMethods } from "../methods/auth-api-method";

const loginAPI = async (request: any): Promise<any> => {
  const response = await callAPI(
    AuthApiMethods.login,
    false,
    request,
    {},
    false,
    false
  );
  return response;
};

export const login = (request: any) => loginAPI(request);
// eslint-disable-next-line import/no-anonymous-default-export
export default {
  login,
};

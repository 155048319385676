import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../store/root-reducer";
import { orderAPI } from "../../../store/screens/order";

const useCancelledOrders = () => {
  const dispatch = useDispatch();
  let cancelledOrdersList = useSelector((state: RootState) => state.orders);
  console.log("cancelled orders list", cancelledOrdersList);

  useEffect(() => {
    let user = localStorage.getItem("user");
    let users = user ? JSON.parse(user) : null;
    let params = {
      user: users,
      status: "Cancelled",
    };
    dispatch(orderAPI(params));
  }, []);

  return {
    cancelledOrdersList
  };
};

export default useCancelledOrders;

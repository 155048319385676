import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Breadcrumb from "../../components/breadcrumb";
import { WhishListCard } from "../../components/cards/whishlist-card";
import { Norecord } from "../../components/norecord";
import ToastNotification from "../../components/toast-notification";
import { useWishlist } from "./components/wishlist-hooks";
import img from "../../assets/img/wishlist.png";

const Wishlist = () => {
  const { wishlists } = useWishlist();
  // console.log(wishlists)
  const [show, setShow] = useState(false);

  return (
    <>
      <div className="breadcrumb-sec">
        <div className="container-lg">
          <div className="row">
            <div className="col-12">
              <Breadcrumb
                breadcrumbs={[
                  { to: "/", name: "Home" },
                  { to: "#", name: "Wishlist" },
                ]}
              />
            </div>
          </div>
        </div>
      </div>
      {wishlists && wishlists.length > 0 ? (
        <div className="container pt-5">
          <div className="wishlist-heading text-center mt-5">
            <h2>My Wishlist</h2>
          </div>
          {/* <h4 className="section-title position-relative text-uppercase mx-xl-5 mb-4">
        <span className="bg-secondary pr-5">Wishlist</span>
        </h4> */}
          <div className="row mb-5">
            <ToastNotification
              setShow={setShow}
              show={show}
              content="Product Remove From Wishlist!"
            />
          </div>
          <div className="row border">
            <div className="col-sm-12 border-end text-center border-bottom col-bg">
              Product Details
            </div>

            {wishlists.map((wishlist: any, index: any) => {
              return (
                <WhishListCard
                  key={index + wishlist?.item_code}
                  name={wishlist.item_code}
                  image={wishlist.image}
                  itemGroup={wishlist.item_group}
                  setShow={setShow}
                  // redirectUrl={"/sub-category/" + category.item_group_name}
                />
              );
            })}
          </div>
        </div>
      ) : (
        <Norecord
          heading="My Wishlist"
          img={img}
          content="Saving something for later? Add products to your wishlist so that you don’t miss out!"
        />
      )}
    </>
  );
};
export default Wishlist;

import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { GETReviewDetailAPI } from "../../../../services/api/review-list-detail-api";

const useReviewReportDetail = () => {
  const { salesOrderID } = useParams();

  const [reviewDetailData, setReviewDetailData] = useState<any>({});
  const callReviewDetailAPI = async () => {
    const getReviewDetailData = await GETReviewDetailAPI(salesOrderID);
    // console.log("review detail data in hook", getReviewDetailData);
    if (
      getReviewDetailData?.status === 200 &&
      getReviewDetailData?.data?.message?.msg === "success"
    ) {
      setReviewDetailData({ ...getReviewDetailData?.data?.message?.data[0] });
    } else {
      setReviewDetailData({});
    }
  };
  useEffect(() => {
    callReviewDetailAPI();
  }, []);

  return reviewDetailData;
};

export default useReviewReportDetail;

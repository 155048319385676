// import { convertCompilerOptionsFromJson } from "typescript";
import store from "../../store/store";
import { callAPI } from "../config/api-core";
import { ProductAPIMethods } from "../methods/product-api-method";

const productFetch = async (
  param1: any,
  param2: any,
  start: any,
  end: any,
  filterData: any,
  sortBySeq: string,
  tagName: any,
  sortByDesc: boolean
): Promise<any> => {
  // console.log(param1);
  // if (param2 === "ARC" || param2 === "ARG" || param2 === "ARF") {
  //   console.log(param1);
  //   let login = localStorage.getItem("token");
  //   let header = {
  //     Authorization: login && JSON.parse(login)?.access_token,
  //   };
  //   console.log("lev", param1);
  //   console.log(filterData);
  //   let params = `level_2_category=${param2}&item_group=${param1}&filters=${"{" + filterData + "}"}`;
  //   const response = await callAPI(
  //     ProductAPIMethods.productList + params,
  //     true,
  //     {
  //       limit_page_length: 20,
  //       limit_start: start,
  //     },
  //     header,
  //     false,
  //     false
  //   );
  //   return response;
  // }

  let params: any;
  let login = localStorage.getItem("token");
  let user = localStorage.getItem("user");
  let users = user ? JSON.parse(user) : null;

  let header = {
    Authorization: login && JSON.parse(login)?.access_token,
  };

  if (sortBySeq !== "" && tagName === "New Arrival") {
    params = `${
      ProductAPIMethods.newArrivalProductList
    }tag=New%20Arrival&filters=${
      "{" + filterData + "}"
    }&sort_by=${sortBySeq}&desc=${sortByDesc === true ? 1 : 0}&user=${users}`;
    // params = `${
    //   ProductAPIMethods.productList
    // }level_2_category=${param1}&filters=${
    //   "{" + filterData + "}"
    // }&sort_by=${sortBySeq}&desc=${sortByDesc === true ? 1 : 0}`;
  } else if (sortBySeq !== "") {
    params = `${
      ProductAPIMethods.productList
    }level_2_category=${param1}&filters=${
      "{" + filterData + "}"
    }&sort_by=${sortBySeq}&desc=${sortByDesc === true ? 1 : 0}&user=${users}`;
    // params = `${
    //   ProductAPIMethods.newArrivalProductList
    // }tag=New%20Arrival&filters=${"{" + filterData + "}"}&sort_by=${sortBySeq}&desc=${sortByDesc === true ? 1 : 0}`;
  } else {
    params = `${
      ProductAPIMethods.productList
    }level_2_category=${param1}&filters=${
      "{" + filterData + "}"
    }&sort_by=${sortBySeq}&desc=${sortByDesc === true ? 1 : 0}&user=${users}`;
  }

  const response = await callAPI(
    params,
    true,
    {
      limit_page_length: 20,
      limit_start: start,
    },
    header,
    false,
    false
  );
  return response;
};

const productDetailFetch = async (request: any): Promise<any> => {
  let login = localStorage.getItem("token");
  let header = {
    Authorization: login && JSON.parse(login)?.access_token,
  };
  // let request ={
  //   item_code: param,
  // }
  const response = await callAPI(
    ProductAPIMethods.productDetailList,
    false,
    request,
    header,
    false,
    false
  );
  return response;
};

const allProductsFetch = async (
  param1: any,
  param2: any,
  filterData: any,
  start: any
): Promise<any> => {
  let login = localStorage.getItem("token");
  let header = {
    Authorization: login && JSON.parse(login)?.access_token,
  };
  let params = `level_1_category=${param1}&level_2_category=${param2}&filters=${
    "{" + filterData + "}"
  }`;
  const response = await callAPI(
    ProductAPIMethods.productList + params,
    true,
    {
      limit_page_length: 20,
      limit_start: start,
    },
    header,
    false,
    false
  );
  return response;
};

const getProductListAPI = (
  param1: any,
  param2: any,
  start: any,
  end: any,
  filter: any,
  sortBySeq: string,
  tagName: any,
  sortByDesc: boolean
) =>
  productFetch(
    param1,
    param2,
    start,
    end,
    filter,
    sortBySeq,
    tagName,
    sortByDesc
  );
const getProductDetailListAPI = (request: any) => productDetailFetch(request);
const getAllProductsListAPI = (
  param1: any,
  param2: any,
  filter: any,
  start: any
) => allProductsFetch(param1, param2, filter, start);
//  const getProductFilterListAPI = (param1:any, param2:any, start: any, end : any) => productFilterFetch(param1, param2, start, end);
// eslint-disable-next-line import/no-anonymous-default-export
export default {
  getProductListAPI,
  getProductDetailListAPI,
  getAllProductsListAPI,
};

import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../store/root-reducer";
// import { productsFilterAPI } from "../store/screens/products";
interface props {
  handleCheckboxChange: any;
  handleFilter: any;
  productData: any;
  productPage?: any;
  checked?: any;
  filtersArr?: any;
}
const Sidebar = ({
  handleCheckboxChange,
  handleFilter,
  productData,
  productPage,
  checked,
  filtersArr,
}: props) => {
  const [searchVal, setsearchVals] = useState("");
  const [btnReset, setbtnReset] = useState(false);
  const getUserSelectedFilters: any = localStorage.getItem("filters");
  const parsedFilters = JSON.parse(getUserSelectedFilters);

  // const Sidebar = ({handleCheckboxChange, handleFilter,productData}:props) => {
  const dispatch = useDispatch();
  const handleSearch = (e: any) => {
    // setsearchVals(e.target.value);
    // dispatch()

    handleFilter("", productPage, searchVal);
    // setsearchVals('');
  };
  // const [checked, setChecked] = useState(false);
  // const check=(e:any)=>{
  //   console.log(e.target.value)
  //   setChecked(e.target.checked)
  // }

  // const productData = useSelector((state: RootState) => state.products);
  console.log("###product data", productData);
  const handleKeyDown = (e: any) => {
    if (e.key === "Enter") {
      handleSearch(e);
      console.log("Enter key pressed ✅");
    }
  };

  const handleDeleteSearch = () => {
    console.log("clicked on delete");
  };

  const handleSelectedFilters = () => {
    if (parsedFilters.hasOwnProperty("weight_range")) {
      return (
        <>
          <div className="widget">
            <h4>Weight Range</h4>
            <fieldset>
              {productData.filters &&
                productData.filters.weight_range.map(
                  (filter: any, index: any) => {
                    return (
                      <div
                        className="filter-options"
                        key={"weight_range" + index}
                      >
                        <input
                          type="checkbox"
                          value={filter}
                          checked={parsedFilters[`weight_range`].includes(
                            '"' + filter + '"'
                          )}
                          onChange={(e) =>
                            handleCheckboxChange(
                              e,
                              filter + index,
                              "weight_range",
                              productPage
                            )
                          }
                          // onChange={(e) => handleCheckboxChange(e, filter.name+index ,'weight_range')}
                        />
                        <label>{filter}</label>
                      </div>
                    );
                  }
                )}
            </fieldset>
          </div>
          {!productPage ? (
            <div className="widget">
              <h4>Sub Category</h4>
              <fieldset>
                {productData.filters &&
                  productData.filters.L2.map((filter: any, index: any) => (
                    <div className="filter-options" key={"L2" + index}>
                      <input
                        type="checkbox"
                        value={filter.name}
                        checked={parsedFilters[`L2`].includes(
                          "" + filter.name + ""
                        )}
                        onChange={(e) =>
                          handleCheckboxChange(
                            e,
                            filter.name + index,
                            "L2",
                            productPage
                          )
                        }
                      />
                      <label>{filter.name}</label>
                    </div>
                  ))}
              </fieldset>
            </div>
          ) : (
            ""
          )}

          <div className="widget">
            <h4 className="sub-cat">Sub Sub Category</h4>
            <fieldset>
              {productData.filters &&
                productData.filters.L3?.length > 0 &&
                productData.filters.L3.map((filter: any, index: any) => (
                  <div className="filter-options" key={"L3" + index}>
                    <input
                      type="checkbox"
                      value={filter.name}
                      checked={parsedFilters[`L3`].includes(
                        '"' + filter.name + '"'
                      )}
                      onChange={(e) =>
                        handleCheckboxChange(
                          e,
                          filter.name + index,
                          "L3",
                          productPage
                        )
                      }
                      // onChange={(e) => handleCheckboxChange(e, filter.name+index,'L3')}
                    />
                    <label>{filter.name}</label>
                  </div>
                ))}
            </fieldset>
          </div>

          {productData.filters?.colour &&
          productData.filters.colour.length > 0 ? (
            <div className="widget">
              <h4 className="sub-cat">Colors</h4>
              <fieldset>
                {productData?.filters &&
                  productData?.filters?.colour.map(
                    (filter: any, index: any) => (
                      <div className="filter-options" key={"Color" + index}>
                        <input
                          type="checkbox"
                          value={filter}
                          checked={parsedFilters[`Color`].includes(
                            '"' + filter + '"'
                          )}
                          onChange={(e) =>
                            handleCheckboxChange(
                              e,
                              filter + index,
                              "Color",
                              productPage
                            )
                          }
                          // onChange={(e) => handleCheckboxChange(e, filter.name+index,'L3')}
                        />
                        <label>{filter}</label>
                      </div>
                    )
                  )}
              </fieldset>
            </div>
          ) : null}
        </>
      );
    } else {
      return (
        <>
          <div className="widget">
            <h4>Weight Range</h4>
            <fieldset>
              {productData?.filters &&
                productData?.filters?.weight_range.map(
                  (filter: any, index: any) => (
                    <div
                      className="filter-options"
                      key={"weight_range" + index}
                    >
                      <input
                        type="checkbox"
                        value={filter}
                        checked={checked[`weight_range`].includes(
                          '"' + filter + '"'
                        )}
                        onChange={(e) =>
                          handleCheckboxChange(
                            e,
                            filter + index,
                            "weight_range",
                            productPage
                          )
                        }
                        // onChange={(e) => handleCheckboxChange(e, filter.name+index ,'weight_range')}
                      />
                      <label>{filter}</label>
                    </div>
                  )
                )}
            </fieldset>
          </div>
          {!productPage ? (
            <div className="widget">
              <h4>Sub Category</h4>
              <fieldset>
                {productData?.filters &&
                  productData?.filters?.L2.map((filter: any, index: any) => (
                    <div className="filter-options" key={"L2" + index}>
                      <input
                        type="checkbox"
                        value={filter.name}
                        checked={checked[`L2`].includes("" + filter.name + "")}
                        onChange={(e) =>
                          handleCheckboxChange(
                            e,
                            filter.name + index,
                            "L2",
                            productPage
                          )
                        }
                      />
                      <label>{filter.name}</label>
                    </div>
                  ))}
              </fieldset>
            </div>
          ) : (
            ""
          )}

          <div className="widget">
            <h4 className="sub-cat">Sub Sub Category</h4>
            <fieldset>
              {productData?.filters &&
                productData?.filters?.L3.map((filter: any, index: any) => (
                  <div className="filter-options" key={"L3" + index}>
                    <input
                      type="checkbox"
                      value={filter.name}
                      checked={parsedFilters[`L3`].includes(
                        '"' + filter.name + '"'
                      )}
                      onChange={(e) =>
                        handleCheckboxChange(
                          e,
                          filter.name + index,
                          "L3",
                          productPage
                        )
                      }
                      // onChange={(e) => handleCheckboxChange(e, filter.name+index,'L3')}
                    />
                    <label>{filter.name}</label>
                  </div>
                ))}
            </fieldset>
          </div>
          {productData.filters?.colour &&
          productData.filters.colour.length > 0 ? (
            <div className="widget">
              <h4 className="sub-cat">Colors</h4>
              <fieldset>
                {productData?.filters &&
                  productData?.filters?.colour.map(
                    (filter: any, index: any) => (
                      <div className="filter-options" key={"Color" + index}>
                        <input
                          type="checkbox"
                          value={filter}
                          checked={checked[`Color`].includes(
                            '"' + filter + '"'
                          )}
                          onChange={(e) =>
                            handleCheckboxChange(
                              e,
                              filter + index,
                              "Color",
                              productPage
                            )
                          }
                          // onChange={(e) => handleCheckboxChange(e, filter.name+index,'L3')}
                        />
                        <label>{filter}</label>
                      </div>
                    )
                  )}
              </fieldset>
            </div>
          ) : null}
        </>
      );
    }
  };
  return (
    <>
      <aside id="sidebar">
        <div className="search-field search-wrapper m-2">
          <div className="input-group search-input-grp pt-2">
            <input
              type="text"
              value={searchVal}
              className="form-control search-box"
              placeholder="Search here"
              aria-label="Search"
              aria-describedby="basic-addon1"
              onChange={(e: any) => setsearchVals(e.target.value)}
              onKeyDown={handleKeyDown}
            />
            {/* {!searchVal  ? null : <button className="btn-close search-reset" type="reset"></button>} */}
            {/* <button className="btn-close search-reset" type="reset"></button> */}
            <button className="input-group-text" id="basic-addon1">
              <i
                className="fa fa-search"
                aria-hidden="true"
                onClick={handleSearch}
              />
            </button>
          </div>
        </div>
        {/* <button onClick={handleDeleteSearch}>X</button > */}
        <h4 className="filter-heading">Filters</h4>
        {/* {handleSelectedFilters()} */}

        <div className="widget">
          <h4>Weight Range</h4>
          <fieldset>
            {productData?.filters &&
              productData?.filters?.weight_range?.length > 0 &&
              productData?.filters?.weight_range.map(
                (filter: any, index: any) => (
                  <div className="filter-options" key={"weight_range" + index}>
                    <input
                      type="checkbox"
                      value={filter}
                      checked={checked[`weight_range`].includes(
                        '"' + filter + '"'
                      )}
                      onChange={(e) =>
                        handleCheckboxChange(
                          e,
                          filter + index,
                          "weight_range",
                          productPage
                        )
                      }
                      // onChange={(e) => handleCheckboxChange(e, filter.name+index ,'weight_range')}
                    />
                    <label>{filter}</label>
                  </div>
                )
              )}
          </fieldset>
        </div>
        {!productPage ? (
          <div className="widget">
            <h4>Sub Category</h4>
            <fieldset>
              {productData?.filters &&
                productData?.filters?.L2?.length > 0 &&
                productData?.filters?.L2.map((filter: any, index: any) => (
                  <div className="filter-options" key={"L2" + index}>
                    <input
                      type="checkbox"
                      value={filter.name}
                      checked={checked[`L2`].includes("" + filter.name + "")}
                      onChange={(e) =>
                        handleCheckboxChange(
                          e,
                          filter.name + index,
                          "L2",
                          productPage
                        )
                      }
                    />
                    <label>{filter.name}</label>
                  </div>
                ))}
            </fieldset>
          </div>
        ) : (
          ""
        )}

        <div className="widget">
          <h4 className="sub-cat">Sub Sub Category</h4>
          <fieldset>
            {productData.filters &&
              productData.filters.L3?.length > 0 &&
              productData.filters.L3.map((filter: any, index: any) => (
                <div className="filter-options" key={"L3" + index}>
                  <input
                    type="checkbox"
                    value={filter.name}
                    checked={checked[`L3`].includes('"' + filter.name + '"')}
                    onChange={(e) =>
                      handleCheckboxChange(
                        e,
                        filter.name + index,
                        "L3",
                        productPage
                      )
                    }
                    // onChange={(e) => handleCheckboxChange(e, filter.name+index,'L3')}
                  />
                  <label>{filter.name}</label>
                </div>
              ))}
          </fieldset>
        </div>
        {productData?.filters?.colour &&
        productData?.filters?.colour.length > 0 ? (
          <div className="widget">
            <h4 className="sub-cat">Colors</h4>
            <fieldset>
              {productData.filters &&
                productData.filters.colour.map((filter: any, index: any) => (
                  <div className="filter-options" key={"Color" + index}>
                    <input
                      type="checkbox"
                      value={filter}
                      checked={checked[`Color`].includes('"' + filter + '"')}
                      onChange={(e) =>
                        handleCheckboxChange(
                          e,
                          filter + index,
                          "Color",
                          productPage
                        )
                      }
                      // onChange={(e) => handleCheckboxChange(e, filter.name+index,'L3')}
                    />
                    <label>{filter}</label>
                  </div>
                ))}
            </fieldset>
          </div>
        ) : null}
        {/* <div className="widget">
          <h4 className="sub-cat">Colors</h4>
          <fieldset>
            {productData.filters && productData.filters.colour.map((filter: any, index: any) => (
              <div className="filter-options" key={'L3' + index}>
                <input
                  type="checkbox"
                  value={filter}
                  checked={checked[`L3`].includes('"' + filter + '"')}
                  onChange={(e) => handleCheckboxChange(e, filter + index, 'L3', productPage)}
                // onChange={(e) => handleCheckboxChange(e, filter.name+index,'L3')}
                />
                <label>{filter}</label>
              </div>
            ))} */}
        {/* <input type="checkbox"/>
            <label>Yellow</label><br/>
            <input type="checkbox"/>
            <label>Rose</label><br/>
            <input type="checkbox"/>
            <label>White</label> */}
        {/* </fieldset> */}
        {/* </div> */}
      </aside>
    </>
  );
};
export default Sidebar;

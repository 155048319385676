import React from "react";
import { Button, Modal } from "react-bootstrap";

const ReportViewModal = ({ show, setShow, reviewData }: any) => {
  const handleClose = () => setShow(false);

  return (
    <div className="container">
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>
            <span className="text-dark">Review</span>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <textarea className="form-control" disabled>
            {reviewData}
          </textarea>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default ReportViewModal;

import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import api from "../../services/api/categories-api";
import { handleResponse } from "../../services/config/handle-response";
import { CONSTANT } from "../../utils/constant";

interface RepoCategoryState {
  category: any;
  error: any;
}

const initialState: RepoCategoryState = {
  category: [],
  error: "",
};

const categoryScreen = createSlice({
  name: "category",
  initialState,
  reducers: {
    categorySuccess(state, action: PayloadAction<RepoCategoryState>) {
      // localStorage.setItem('user', JSON.stringify(action.payload));
      state.category = action.payload;
      state.error = "";
    },
    categoryFailed(state) {
      state.error = "Error or Occured";
    },
  },
});

const { categorySuccess, categoryFailed } = categoryScreen.actions;

export const categoryAPI = (type:any): any => async (dispatch: any) => {
  console.log(type);
  try {
    const response = await api.getCategoriesListAPI(type);
    const result = dispatch(handleResponse(response));
    if (result) {
      dispatch(categorySuccess(result));
    } else {
      dispatch(categoryFailed());
    }
  } catch (err) {}
};

export default categoryScreen.reducer;

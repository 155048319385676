import {combineReducers} from "@reduxjs/toolkit";
import loginReducer from "./screens/login";
import categoryReducer from "./screens/category";
import subcategoryReducer from "./screens/subcategory";
import productReducer from "./screens/products";
import wishlistReducer from "./screens/wishlist";
import cartReducer from "./screens/cart";
import purityReducer from "./screens/purity";
import orderReducer from "./screens/order";
import navitemsReducer from "./screens/navitems";

const rootReducer = combineReducers({
  login: loginReducer,
  categories: categoryReducer,
  subcategories: subcategoryReducer,
  products: productReducer,
  wishlists: wishlistReducer,
  carts: cartReducer,
  puritys: purityReducer,
  orders: orderReducer,
  navitem: navitemsReducer
});

export type RootState = ReturnType<typeof rootReducer>;
export default rootReducer;
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../store/root-reducer";
import { categoryAPI } from "../../../store/screens/category";

export const useCategory = () => {
  const dispatch = useDispatch();
  const category = useSelector((state: RootState) => state.categories);
  const [categories, setCategories] = useState([]);
  console.log(categories)

  useEffect(() => {
    dispatch(categoryAPI("Chains"));
  }, []);

  useEffect(() => {
    setCategories(category.category);
  }, [category]);

  return {
    categories,
  };
};

import { useEffect, useState } from "react";
import { Button, Offcanvas } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useCategory } from "../pages/category/components/category-hooks";
import { useSubcategory } from "../pages/subcategory/components/subcategory-hooks";
import { RootState } from "../store/root-reducer";
import { navitemsAPI } from "../store/screens/navitems";
import { subcategoryAPI } from "../store/screens/subcategory";

const Sidebar = (props: any) => {
  const { show, onHide } = props;
  const navitemss = useSelector((state: RootState) => state.navitem);
  const [navitems, setNavitems] = useState<any[]>([]);
  // const { categories } = useCategory();
  // const { subcategories } = useSubcategory();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(navitemsAPI())
  }, [])
  useEffect(() => {
    setNavitems(navitemss.navitems);
  }, [navitemss]);

  // console.log(navitems)

  // const handleSub = async (e: any, name: any) => {
  //   e.preventDefault();
  //   await dispatch(subcategoryAPI(name));
  // };
  const [searchTerm, setSearchTerm] = useState("");
  // console.log(searchTerm);
  const navigate = useNavigate();
  const param = useParams();
  const [shows, setShows] = useState(false);
  const closeSidebar = () => setShows(false);
  const openSidebar = () => setShows(true);
  const handleSearch = (e: any) => {
    e.preventDefault();
    navigate(
      "/product-detail/" +
      param.categoryId +
      "/" +
      param.subCategoryId +
      "/" +
      searchTerm
    );
  };

  const handleKeyDown = (e: any) => {
    if (e.key === "Enter") {
      handleSearch(e);
      console.log("Enter key pressed ✅");
    }
  };

  return (
    <>
      <Offcanvas show={show} onHide={onHide}>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title></Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <div className="search-field">
            <div className="input-group d-sm-none">
              <input
                type="text"
                className="form-control"
                placeholder="Search here"
                aria-label="Search"
                aria-describedby="basic-addon1"
                onChange={(e: any) => setSearchTerm(e.target.value)}
                onKeyDown={handleKeyDown}
              />
              <button
                className="input-group-text"
                id="basic-addon1"
                onClick={(e: any) => handleSearch(e)}
              // onKeyPress={(e) => {
              //       if (e.key === "Enter") {
              //         // eslint-disable-next-line @typescript-eslint/no-unused-expressions
              //         (e: any) => handleSearch(e)
              //       }
              //   }}
              >
                <i
                  className="fa fa-search"
                  aria-hidden="true"
                // onClick={(e: any) => handleSearch(e)}
                />
              </button>
            </div>
          </div>
          <ul className="navbar-nav vertical-nav">
            {navitems.length > 0 &&
              navitems.map((category: any, index: any) => {
                return (
                  <div className="dropdown dropdown-border">
                    <li
                      className="nav-item"
                      key={index + category?.category}
                    >
                      <Link to="#"
                        className="nav-link dropdown-toggle"
                        id="navbarDropdown"
                        role="button"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      // onClick={(e: any) => handleSub(e, category.name)}
                      >
                        {category.category}
                      </Link>
                      <ul
                        className="dropdown-menu drop-border"
                        aria-labelledby="navbarDropdown"
                      >
                        {navitems[index].subCategory.length > 0 &&
                          navitems[index].subCategory.map((subcategory: any, index: any) => {
                            // console.log(category.category);
                            // console.log(subcategory.name);
                            return (
                              <li key={index + subcategory?.name}>
                                <Link
                                  to={
                                    "/products/" +
                                    category.category +
                                    "/" +
                                    subcategory.name + "/" + 1
                                  }
                                  className="dropdown-item"
                                  onClick={onHide}
                                >
                                  {subcategory.name}
                                </Link>
                              </li>
                            );
                          })}
                      </ul>
                    </li>
                    {/* <hr /> */}
                  </div>
                );
              })}
          </ul>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};
export default Sidebar;

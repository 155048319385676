import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { RootState } from "../../../store/root-reducer";
import { cartAPI, PostCartAPI } from "../../../store/screens/cart";
import { orderAPI, PlaceOrderAPI } from "../../../store/screens/order";

export const useCart = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const cart = useSelector((state: RootState) => state.carts);
  const [carts, setCarts] = useState<any>([]);
  const [finalWeight, setFinalWeight] = useState(0);
  const [deliveryNotification, setdeliveryNotification] = useState(false);
  const orderDataReorder = useSelector(
    (state: RootState) => state.orders.orderdetail
  );
  const orderReOrderCustomerName = orderDataReorder?.cust_name;
  const [editableCustomerName, setEditableCustomerName] = useState(
    orderReOrderCustomerName
  );

  useEffect(() => {
    let user = localStorage.getItem("user");
    let users = user ? JSON.parse(user) : null;
    let params = {
      user: users,
    };
    dispatch(cartAPI(params));
  }, []);

  useEffect(() => {
    console.log(cart);
    setCarts(cart.cart);
  }, [cart]);

  /////////////////

  // useEffect(() => {
  //   let totalWeight: any = 0;
  //   carts &&
  //     carts.map((item: any, index: any) => {
  //     //  item.order.map((data:any, index:any)=>{
  //          totalWeight += (item.total_weight);
  //       // })
  //       setFinalWeight(totalWeight);
  //     });

  // }, [carts.length,carts]);

  // console.log(finalWeight);

  const handlePlace = async (
    e: any,
    common_comment: any,
    estimatedDate: any
  ) => {
    e.preventDefault();
    console.log("date hook", estimatedDate);
    let fifteenDaysAheadDate;
    let enteredDate;
    const getTodaysDate = new Date();
    fifteenDaysAheadDate = new Date(
      getTodaysDate.getFullYear(),
      getTodaysDate.getMonth(),
      getTodaysDate.getDate() + 15
    );

    const date = estimatedDate;
    const [day, month, year] = date.split("/");

    enteredDate = new Date(year, month - 1, day);

    if (estimatedDate === "") {
      const today = new Date();
      today.setDate(today.getDate() + 15);
      const day = String(today.getDate()).padStart(2, "0");
      const month = String(today.getMonth() + 1).padStart(2, "0"); // Months are zero-based
      const year = today.getFullYear();
      const trimmed_date = [day, month, year].join("/");
      let user = localStorage.getItem("user");
      let users = user ? JSON.parse(user) : null;
      console.log(estimatedDate);
      let params = {
        user: users,
        common_comment: common_comment,
        payment_date: trimmed_date,
      };
      localStorage.removeItem("customer-name");
      await dispatch(PlaceOrderAPI(params));
      await dispatch(orderAPI(params));
      localStorage.setItem("product-codes", "[]");
      await navigate("/order-list");
    }

    if (enteredDate >= fifteenDaysAheadDate) {
      console.log("check");
      let user = localStorage.getItem("user");
      let users = user ? JSON.parse(user) : null;
      console.log(estimatedDate);
      let params = {
        user: users,
        common_comment: common_comment,
        payment_date: estimatedDate,
      };
      localStorage.removeItem("customer-name");
      // await dispatch(PlaceOrderAPI(params));
      // await dispatch(orderAPI(params));
      localStorage.setItem("product-codes", "[]");
      await navigate("/order-list");
    } else {
      // console.log("see");
      setdeliveryNotification(true);

      // enteredDate = fifteenDaysAheadDate
    }
  };

  let user = localStorage.getItem("user");
  let users = user ? JSON.parse(user) : null;
  let userData = {
    user: users,
  };

  const orderData = useSelector((state: RootState) => state.orders.orderdetail);

  const reOrderCustomerName = orderData?.cust_name;
  const reOrderDeliveryDate = orderData?.estimated_delivery_date;
  const reOrderTotalGrandWeight = orderData?.total_grand_weight;
  const reOrderOrderDetail = orderData?.data?.map((ele: any) => ele?.orders);

  // Declare variables outside
  let itemCode: any;
  let reorderPurity: any;
  let orderDetails: any = [];
  let colour: any, qty: any, size: any, weight: any;
  // Accessing item_code if there is an extra array level
  if (
    Array.isArray(reOrderOrderDetail) &&
    reOrderOrderDetail.length > 0 &&
    Array.isArray(reOrderOrderDetail[0]) &&
    reOrderOrderDetail[0].length > 0
  ) {
    itemCode = reOrderOrderDetail[0][0].item_code;
    reorderPurity = reOrderOrderDetail[0][0].purity;
    console.log("Item Code:", itemCode);

    // Accessing the order array
    orderDetails = reOrderOrderDetail[0][0].order;
    if (Array.isArray(orderDetails) && orderDetails.length > 0) {
      ({ colour, qty, size, weight } = orderDetails[0]);
    }
    console.log("Order Data:", orderDetails);
  } else {
    console.log("Data is not in expected format");
  }

  const handleCustomerName = (e: any) => {
    setEditableCustomerName(e.target.value);
  };

  const [reOrderQtyIncrease, setReOrderQtyIncrease] = useState(0);
  const [reOrderQtyDecrease, setReOrderQtyDecrease] = useState(0);
  const [reOrderQtyValue, setReOrderQtyValue] = useState(0);
  const handleReOrderQuantityIncreased = async (
    qty: any,
    size: any,
    colour: any
  ) => {
    setReOrderQtyIncrease(qty + 1);
    // let params: any;
    // params = {
    //   user: users,
    //   cust_name: getCustomerName,
    //   item_code: name,
    //   purity: purity,
    //   // colour: productColor,
    //   colour: getSelectedColor,
    //   remark: remark,
    //   wastage: wastage,
    //   qty_size_list: [
    //     { qty: `${qty + 1}`, size: `${size}`, colour: `${colour}` },
    //   ],
    // };
    // console.log("qty ", params);
  };

  const handleReOrderQuantityDecreased = async (
    name: any,
    qty: any,
    size: any,
    colour: any
  ) => {
    if (qty - 1 !== 0) {
      setReOrderQtyDecrease(qty - 1);
    }
  };

  const handleReOrderInput = async (value: any, size: any, colour: any) => {
    setReOrderQtyValue(value);
  };

  const handleReOrderPlace = async (
    e: any,
    common_comment: any,
    estimatedDate: any
  ) => {
    e.preventDefault();

    const params = {
      cust_name: editableCustomerName,
      item_code: itemCode,
      purity: reorderPurity,
      qty_size_list: [
        {
          qty: reOrderQtyIncrease !== 0 ? reOrderQtyIncrease : qty,
          size: size,
          colour: colour,
        },
      ],
      colour: colour,
      remark: "",
      user: users,
      wastage: "",
    };

    if (!params) return;

    await dispatch(PostCartAPI(params)); // Call PostCartAPI
    // await dispatch(cartAPI(userData)); // Call cartAPI

    console.log("date hook", estimatedDate);
    let fifteenDaysAheadDate;
    let enteredDate;
    const getTodaysDate = new Date();
    fifteenDaysAheadDate = new Date(
      getTodaysDate.getFullYear(),
      getTodaysDate.getMonth(),
      getTodaysDate.getDate() + 15
    );

    const date = estimatedDate;
    const [day, month, year] = date.split("/");

    enteredDate = new Date(year, month - 1, day);

    if (estimatedDate === "") {
      const today = new Date();
      today.setDate(today.getDate() + 15);
      const day = String(today.getDate()).padStart(2, "0");
      const month = String(today.getMonth() + 1).padStart(2, "0"); // Months are zero-based
      const year = today.getFullYear();
      const trimmed_date = [day, month, year].join("/");
      let user = localStorage.getItem("user");
      let users = user ? JSON.parse(user) : null;
      console.log(estimatedDate);
      let params = {
        user: users,
        common_comment: common_comment,
        payment_date: trimmed_date,
      };
      localStorage.removeItem("customer-name");
      await dispatch(PlaceOrderAPI(params));
      await dispatch(orderAPI(params));
      localStorage.setItem("product-codes", "[]");
      // await navigate("/order-list");
    }

    if (enteredDate >= fifteenDaysAheadDate) {
      console.log("check");
      let user = localStorage.getItem("user");
      let users = user ? JSON.parse(user) : null;
      console.log(estimatedDate);
      let placeOrderParams = {
        user: users,
        common_comment: common_comment,
        payment_date: estimatedDate,
      };
      localStorage.removeItem("customer-name");
      await dispatch(PlaceOrderAPI(placeOrderParams));
      await dispatch(orderAPI(placeOrderParams));
      localStorage.setItem("product-codes", "[]");
      // await navigate("/order-list");
    } else {
      setdeliveryNotification(true);
    }
  };

  // const handlePlace = async (
  //   e: any,
  //   common_comment: any,
  //   estimatedDate: any
  // ) => {
  //   e.preventDefault();
  //   console.log(estimatedDate);
  //   let editTodaysDate;
  //   const getTodaysDate = new Date();
  //   editTodaysDate = new Date(getTodaysDate.getFullYear(),getTodaysDate.getMonth(),getTodaysDate.getDate());
  //   // console.log(e.target.value)
  //   const date = estimatedDate;
  //   const [day,month,year] = estimatedDate.split("/");
  //   console.log(year)
  //   console.log(month)
  //   console.log(day)

  //   const getTodaysDate = new Date();
  //   editTodaysDate = new Date(getTodaysDate.getFullYear(),getTodaysDate.getMonth(),getTodaysDate.getDate());
  //   const enteredDate = new Date(year, month - 1, day);
  //   console.log(enteredDate)
  //   console.log(editTodaysDate)
  //   console.log(typeof enteredDate)
  //   console.log(typeof editTodaysDate)

  //   if (enteredDate.toString() >= editTodaysDate.toString()) {
  //     console.log("check")
  //     setdeliveryNotification(false)
  //     // let user = localStorage.getItem("user");
  //     // let users = user ? JSON.parse(user) : null;
  //     // console.log("estimated date in cart hook", estimatedDate);
  //     // let params = {
  //     //   user: users,
  //     //   common_comment: common_comment,
  //     //   payment_date: estimatedDate,
  //     // };
  //     // await dispatch(PlaceOrderAPI(params));
  //     // await dispatch(orderAPI(params));
  //     // localStorage.setItem("product-codes", "[]");
  //     // await navigate("/order-list");
  //   }
  //   else{
  //     console.log("see")
  //     setdeliveryNotification(true);
  //   }
  //   // console.log(common_comment)
  //   // let user = localStorage.getItem("user");
  //   // let users = user ? JSON.parse(user) : null;
  //   // console.log("estimated date in cart hook",estimatedDate);
  //   // let params = {
  //   //   user: users,
  //   //   common_comment: common_comment,
  //   //   payment_date: estimatedDate
  //   // };
  //   // await dispatch(PlaceOrderAPI(params));
  //   // await dispatch(orderAPI(params));
  //   // localStorage.setItem("product-codes", "[]");
  //   // await navigate("/order-list");
  // };

  return {
    carts,
    finalWeight,
    handlePlace,
    deliveryNotification,
    setdeliveryNotification,
    handleReOrderPlace,
    reorderPurity,
    editableCustomerName,
    handleCustomerName,
    handleReOrderQuantityIncreased,
    reOrderQtyIncrease,
    handleReOrderQuantityDecreased,
    reOrderQtyDecrease,
    handleReOrderInput,
  };
};

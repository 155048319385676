import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../store/root-reducer";
import { orderAPI } from "../../../store/screens/order";

const useCompletedOrders = () => {
  const dispatch = useDispatch();
  let completedOrdersList = useSelector((state: RootState) => state.orders);
  console.log("completed orders list", completedOrdersList);

  useEffect(() => {
    let user = localStorage.getItem("user");
    let users = user ? JSON.parse(user) : null;
    let params = {
      user: users,
      status: "Completed",
    };
    dispatch(orderAPI(params));
  }, []);

  return {
    completedOrdersList
  };
};

export default useCompletedOrders;

import * as Yup from "yup";

export const validation = Yup.object().shape({
  // usr: Yup.string()
  //   .email(" Enter valid email")
  //   .required(" Email field required"),
  pwd: Yup.string()
    .required(" Password field required")
    .min(6, " Password is too short - should be 6 chars minimum."),
});

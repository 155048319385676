import store from "../../store/store";
import {callAPI} from "../config/api-core";
import {PurityAPIMethods} from "../methods/purity-api-method";

const purityFetch = async (): Promise<any> => {
  let login =  localStorage.getItem('token');
  let header = {
    Authorization: login && JSON.parse(login)?.access_token,
  };
  // let params = `&level_2_category=${param1}&filters=${"{"+filterData+"}"}`
  const response = await callAPI(
    PurityAPIMethods.purityList,
    true,
    {},
    header,
    false,
    false
  );
  return response;
};

  const getPurityListAPI = () => purityFetch();

  export default {
     getPurityListAPI,
  };
  
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { handleResponse } from "../../services/config/handle-response";
import api from "../../services/api/purity-api";

interface RepoPuritysState {
  puritys: any;
  selectedPurity: any;
  error: any;
}

const initialState: RepoPuritysState = {
  puritys: [],
  selectedPurity: "",
  error: "",
};

const puritysScreen = createSlice({
  name: "puritys",
  initialState,
  reducers: {
    puritysSuccess(state, action: PayloadAction<RepoPuritysState>) {
      state.puritys = action.payload;
    },
    puritysFailed(state) {
      state.error = "Error or Occured";
    },
    selectedPurityReducer(state, action: PayloadAction<RepoPuritysState>) {
      state.selectedPurity = action.payload;
    },
  },
});

const { puritysSuccess, puritysFailed } = puritysScreen.actions;
export const { selectedPurityReducer } = puritysScreen.actions;

export const puritysAPI = (): any => async (dispatch: any) => {
  try {
    const response = await api.getPurityListAPI();
    const result = dispatch(handleResponse(response));
    console.log(result);
    if (result) {
      dispatch(puritysSuccess(result));
    } else {
      dispatch(puritysFailed());
    }
  } catch (err) {}
};

export default puritysScreen.reducer;

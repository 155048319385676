import React from 'react';
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";
import { CONSTANT } from '../../utils/constant';

interface Props {
  Imageslideshow?: string[];
}

const ProductImage: React.FC<Props> = ({ Imageslideshow }) => {
  const imageGalleryItems = Imageslideshow?.map((image) => ({
    original: CONSTANT.BASE_URL + image,
    thumbnail: CONSTANT.BASE_URL + image,
  })) || [];
 console.log("@@image",imageGalleryItems)
  return (
    <div>
      {imageGalleryItems.length > 0 ? (
        <ImageGallery items={imageGalleryItems} />
      ) : (
        "Image Not Available"
      )}
    </div>
  );
};

export default ProductImage;

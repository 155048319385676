export const handleResponse = (response: any) => (dispatch: any) => {
  if (response.ok) {
    try {
      const data = (response.data.message) ? response.data.message : response.data.data;
      return data;
    } catch {
      return {status: 500};
    }
  } else if (!response.ok) {
    return {status: 500};
  } else {
    return {status: 500};
  }
};
export const handleDbResponse = (response: any) => (dispatch: any) => {
  if (response.length > 0) {
    try {
      const data = response;
      return {statusCode: 200, result: data};
    } catch {
      return {statusCode: 200, status: 500, result: []};
    }
  } else {
    return {statusCode: 200, status: 500, result: []};
  }
};

import { Modal } from "react-bootstrap";

interface props {
  show: any;
  onHide: any;
  purity: string;
  clearCart?: any;
}

const PopupModal = ({ show, onHide, purity, clearCart }: props) => {
  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Dialog>
        <Modal.Body>
          <h3>
            <strong>Please complete your {purity} order or clear cart</strong>
          </h3>
          <div className="btn-group gap-5 mt-3">
            <button type="button" onClick={onHide} className="btn-popup">
              Continue {purity}
            </button>
            {clearCart !== null && (
              <button
                type="button"
                className="clear btn-popup"
                onClick={clearCart}
              >
                Clear Cart
              </button>
            )}
          </div>
        </Modal.Body>
      </Modal.Dialog>
    </Modal>
  );
};
export default PopupModal;

import axios from "axios";
import { DEFAULT_API_CONFIG } from "../config/api-config";

export const getPendingReportAPI: any = async () => {
  let response;
  let login: any = localStorage.getItem("token");
  let user: any = localStorage.getItem("user"); // Retrieve the user from localStorage

  if (user !== null) {
    user = JSON.parse(user);
  } else {
    return;
  }

  const config = {
    headers: {
      Authorization: login && JSON.parse(login)?.access_token,
    },
  };
  const url = `${DEFAULT_API_CONFIG?.url}/api/method/digitalcatalog_api_erpnext.api.sales_order_reports.pending_orders_list?user=${user}`;

  await axios
    .get(`${url}`, config)
    .then((res: any) => {
      console.log("get pending report api", res);
      response = res;
    })
    .catch((err: any) => {
      console.log("get pending report api err", err);
      response = err;
    });
  return response;
};

export const getInProcessReportAPI: any = async () => {
  let response;
  let login: any = localStorage.getItem("token");
  let user: any = localStorage.getItem("user");

  if (user !== null) {
    user = JSON.parse(user);
  } else {
    return;
  }
  const config = {
    headers: {
      Authorization: login && JSON.parse(login)?.access_token,
    },
  };
  const url = `${DEFAULT_API_CONFIG?.url}api/method/digitalcatalog_api_erpnext.api.sales_order_reports.in_process_orders_list?user=${user}`;

  await axios
    .get(`${url}`, config)
    .then((res: any) => {
      console.log("get in proocess report api", res);
      response = res;
    })
    .catch((err: any) => {
      console.log("get in proocess report api err", err);
      response = err;
    });
  return response;
};

export const getDispatchedReportAPI: any = async () => {
  let response;
  let login: any = localStorage.getItem("token");
  let user: any = localStorage.getItem("user");

  if (user !== null) {
    user = JSON.parse(user);
  } else {
    return;
  }
  const config = {
    headers: {
      Authorization: login && JSON.parse(login)?.access_token,
    },
  };
  const url = `${DEFAULT_API_CONFIG?.url}/api/method/digitalcatalog_api_erpnext.api.sales_order_reports.dispatched_orders_list?user=${user}`;

  await axios
    .get(`${url}`, config)
    .then((res: any) => {
      console.log("get dispatched report api", res);
      response = res;
    })
    .catch((err: any) => {
      console.log("get  dispatched report api err", err);
      response = err;
    });
  return response;
};

export const getReviewReportAPI: any = async () => {
  let response;
  let login: any = localStorage.getItem("token");
  let user: any = localStorage.getItem("user");

  if (user !== null) {
    user = JSON.parse(user);
  } else {
    return;
  }
  const config = {
    headers: {
      Authorization: login && JSON.parse(login)?.access_token,
    },
  };
  const url = `${DEFAULT_API_CONFIG?.url}/api/method/digitalcatalog_api_erpnext.api.sales_order_reports.review_dispatched_orders_list?user=${user}`;

  await axios
    .get(`${url}`, config)
    .then((res: any) => {
      response = res;
    })
    .catch((err: any) => {
      response = err;
    });
  return response;
};

export const getDueDateReminderReportAPI: any = async () => {
  let response;
  let login: any = localStorage.getItem("token");
  let user: any = localStorage.getItem("user");

  if (user !== null) {
    user = JSON.parse(user);
  } else {
    return;
  }
  const config = {
    headers: {
      Authorization: login && JSON.parse(login)?.access_token,
    },
  };
  const url = `${DEFAULT_API_CONFIG?.url}/api/method/digitalcatalog_api_erpnext.api.sales_order_reports.due_date_orders_list?user=${user}`;

  await axios
    .get(`${url}`, config)
    .then((res: any) => {
      console.log("get due reminder report api", res);
      response = res;
    })
    .catch((err: any) => {
      console.log("get due reminder report api err", err);
      response = err;
    });
  return response;
};

export const getLateOrderReportAPI: any = async () => {
  let response;
  let login: any = localStorage.getItem("token");
  let user: any = localStorage.getItem("user");

  if (user !== null) {
    user = JSON.parse(user);
  } else {
    return;
  }
  const config = {
    headers: {
      Authorization: login && JSON.parse(login)?.access_token,
    },
  };
  const url = `${DEFAULT_API_CONFIG?.url}/api/method/digitalcatalog_api_erpnext.api.sales_order_reports.late_orders_list?user=${user}`;

  await axios
    .get(`${url}`, config)
    .then((res: any) => {
      console.log("get late order report api", res);
      response = res;
    })
    .catch((err: any) => {
      console.log("get late order report api err", err);
      response = err;
    });
  return response;
};

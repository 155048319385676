import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { RootState } from "../../../store/root-reducer";
import { productdetailAPI } from "../../../store/screens/products";

export const useProductDetail = () => {
  const [detail, setdetail] = useState<any>([]);
  const param = useParams();
  const dispatch = useDispatch();
  let user: any = localStorage.getItem("user");
  let users = user ? JSON.parse(user) : null;

  const productdetail = useSelector(
    (state: RootState) => state.products.productDetail
  );

  const productPrevNextData = useSelector(
    (state: RootState) => state.products.storePrevAndNextProducts
  );

  console.log("pa", param);
  let params = {
    item_code: param.productId,
    fields: "*",
    user: users,
  };
  console.log(params);
  console.log(productdetail);

  useEffect(() => {
    console.log("test");
    dispatch(productdetailAPI(params));
  }, [params.item_code]);

  // useEffect(()=>
  // {
  //   setdetail()
  // },[])

  return {
    productdetail,
    productPrevNextData,
  };
};

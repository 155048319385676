import React, {
  FunctionComponent as Component,
  useState,
  Suspense,
  useRef,
} from "react";
import { CardInterface } from "./category-interface";
// import { CONSTANT } from "../../utils/constant";
import { DEFAULT_API_CONFIG } from "../../services/config/api-config";
import "../../assets/css/product-card.css";
import noimage from "./../../assets/img/nia.jpg";
import imageLoader from "./../../assets/img/image-loader2.gif";
export const ProductImage: Component<CardInterface> = (props: any) => {
  const { name, image } = props;
  const [loading, setLoading] = useState(true);
  const counter = useRef(0);

  const imageLoaded = () => {
    setLoading(false);
  };

  return (
    <Suspense fallback={<div>Loading</div>}>
      <div style={{ display: loading ? "block" : "none" }}>
        <img src={imageLoader} className="product-image" alt={""} />
      </div>
      <div style={{ display: loading ? "none" : "block" }}>
        <img
          className="product-image"
          title={"#" + name}
          src={image ? DEFAULT_API_CONFIG.url + image : noimage}
          onError={() => noimage}
          alt={""}
          onLoad={imageLoaded}
        />
      </div>
    </Suspense>
  );
};

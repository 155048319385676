import React from 'react'
import { CategoryCard } from "../../components/cards/category-card";
import { useCategory } from '../Turkey/components/turkey_hooks';

const Turkey = () => {
    const { categories } = useCategory();
  console.log(categories);
  return (
    <div className="container mt-5 pt-5">
      <div className="row px-xl-5 pb-3">
        {categories.length > 0 &&
          categories.map((category: any, index: any) => {
            return (
              <CategoryCard
                key={index + category?.name}
                name={category.name}
                image={category.image}
                col={category.layout}
                redirectUrl={"/sub-category/" + category.name}
              />
            );
          })}
      </div>
    </div>
  );
}

export default Turkey

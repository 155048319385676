import { AnyAction, createSlice, PayloadAction } from "@reduxjs/toolkit";
import api from "../../services/api/products-api";
import { handleResponse } from "../../services/config/handle-response";
import { CONSTANT } from "../../utils/constant";

interface RepoProductsState {
  product: any;
  products: any;
  productDetail: any;
  allproducts: any;
  filtersArr: any;
  storePrevAndNextProducts: any;
  error: any;
}

const initialState: RepoProductsState = {
  product: [],
  products: [],
  productDetail: [],
  allproducts: [],
  filtersArr: [],
  storePrevAndNextProducts: [],
  error: "",
};

const productsScreen = createSlice({
  name: "products",
  initialState,
  reducers: {
    productsSuccess(state, action: PayloadAction<RepoProductsState>) {
      console.log("prev and next in reducer", action.payload);
      // below code is to get name from array of objs and store in new array which contains
      // objects with keys prev and next [feature: want to show prev and next in product detail page so that user can navigate]
      const data = action.payload.product.map((item: any, index: any) => {
        const prev =
          index === 0 ? null : action.payload.product[index - 1].name;
        const next =
          index === action.payload.product.length - 1
            ? null
            : action.payload.product[index + 1].name;
        const obj: any = {};
        obj[item.name] = { prev, next };
        return obj;
      });
      console.log("prev and next in reducer data", data);
      state.products = action.payload;
      state.storePrevAndNextProducts = data;
      state.error = "";
    },
    productsFailed(state) {
      state.products = [];
      state.error = "Error or Occured";
    },
    productsFiltersSuccess(state, action) {
      console.log("second try filters in url reducer", action.payload);
      state.filtersArr = action.payload;
    },
    clearFilters(state) {
      state.filtersArr = [];
    },
    productdetailSuccess(state, action: PayloadAction<RepoProductsState>) {
      // console.log("prev and next in detail reducer", state.storePrevAndNextProducts);
      state.productDetail = action.payload;
      state.error = "";
    },
    productdetailFailed(state) {
      state.productDetail = [];
      state.error = "Error or Occured";
    },
    allproductsSuccess(state, action: PayloadAction<RepoProductsState>) {
      state.allproducts = action.payload;
      state.error = "";
    },
    allproductsFailed(state) {
      state.error = "Error or Occured";
    },
  },
});

const {
  productsSuccess,
  productsFailed,
  productdetailSuccess,
  productsFiltersSuccess,
  clearFilters,
  productdetailFailed,
  allproductsSuccess,
  allproductsFailed,
} = productsScreen.actions;

export const {} = productsScreen.actions;
// export const handleFilters = (filtersData:AnyAction):any => (dispatch:any)=>
// {
//   dispatch(productsFiltersSuccess(filtersData));
// }

export const handleFilters = (filtersData: any) => {
  console.log("filters in url in reducer custom function", filtersData);
  return (dispatch: any) => {
    dispatch(productsFiltersSuccess(filtersData));
  };
};
export const handleClearFilters = (): any => (dispatch: any) => {
  dispatch(clearFilters());
};
export const productsAPI =
  (
    param1: any,
    param2: any,
    start: any,
    end: any,
    filterData: any,
    sortBySeq: string,
    sortByDesc:boolean,
    tagName?: any,
  ): any =>
  async (dispatch: any) => {
    try {
      // dispatch(productsFiltersSuccess(allFilterData));
      const response = await api.getProductListAPI(
        param1,
        param2,
        start,
        end,
        filterData,
        sortBySeq,
        tagName,
        sortByDesc
      );
      const result = dispatch(handleResponse(response));
      console.log("detail result ", result);
      if (result) {
        dispatch(productsSuccess(result));
      } else {
        dispatch(productsFailed());
      }
    } catch (err) {}
  };

export const productdetailAPI =
  (request: any): any =>
  async (dispatch: any) => {
    console.log(request);
    try {
      const response = await api.getProductDetailListAPI(request);
      const result = dispatch(handleResponse(response));
      console.log(result);
      if (result) {
        dispatch(productdetailSuccess(result));
      } else {
        dispatch(productdetailFailed());
      }
    } catch (err) {}
  };

export const allproductsAPI =
  (param1: any, param2: any, filterData: any, start: any): any =>
  async (dispatch: any) => {
    try {
      const response = await api.getAllProductsListAPI(
        param1,
        param2,
        filterData,
        start
      );
      const result = dispatch(handleResponse(response));
      console.log("abc", result);
      if (result) {
        dispatch(allproductsSuccess(result));
      } else {
        dispatch(allproductsFailed());
      }
    } catch (err) {}
  };

export default productsScreen.reducer;

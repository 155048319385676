import { useEffect, useState } from "react";

import { Link } from "react-router-dom";
import Breadcrumb from "../../components/breadcrumb";
import { Norecord } from "../../components/norecord";
import img from "../../assets/img/order-list.png";
import "../../assets/css/order-list.css";
import useCancelledOrders from "./components/cancelled-orders-hook";
const CancelledOrders = () => {
  const { cancelledOrdersList} = useCancelledOrders();

  console.log("cancelled orders", cancelledOrdersList);
  return (
    <>
      <div className="breadcrumb-sec">
        <div className="container-lg">
          <div className="row">
            <div className="col-12">
              <Breadcrumb
                breadcrumbs={[
                  { to: "/", name: "Home" },
                  { to: "/order-list", name: "Order List" },
                ]}
              />
            </div>
          </div>
        </div>
      </div>
      {cancelledOrdersList?.orderlist?.rejected_orders?.length > 0 ? (
        <div className="container-lg pt-5">
          <div className="order-list-cont">
            <div className="row pb-3">
              <div className="order-heading text-center">
                <h2>Cancelled Orders List</h2>
              </div>
              <div className="cart-table">
                <table>
                  <tr style={{ fontSize: "16px !important" }}>
                    <th>Order Id</th>
                    <th>Transaction Date</th>
                    <th>Status</th>
                    <th>Total Ordered Weight</th>
                    <th>Delivery Date</th>
                    {window.location.pathname && <th>Total Dispatched Weight</th>}
                    {/* <th>Total Dispatch Weight</th> */}
                  </tr>

                  {cancelledOrdersList?.orderlist?.rejected_orders?.map((order: any, index: any) => {
                    return (
                      <tr key={index + order?.name}>
                        <td>
                          <Link
                            to={"/order-detail/" + order.name+"?status=Cancelled"}
                            className="order-link"
                          >
                            <b>{order.name}</b>
                          </Link>
                        </td>
                        <td>
                          <strong>{order.transaction_date}</strong>
                        </td>
                        <td>
                          <strong>{order.status}</strong>
                        </td>
                        <td className="text-end">
                          <strong>{order.total_weight.toFixed(2)} gm</strong>
                        </td>
                        <td className="text-end">
                          <strong>{order.delivery_date} </strong>
                        </td>
                        <td className="text-end">
                          <strong>{parseFloat(order.dispatch_weight).toFixed(2)} gm</strong>
                        </td>
                        {/* <td>
                          <input
                            type="text"
                            name="dispatch_weight"
                            id="dispatch_weight"
                            // value={dispatchWeight}
                            onChange={changeDispatchWeight}
                          />
                        </td>
                        <td>
                          <button className=" ps-5 pe-5 " onClick={()=>handleDispatch(order.name,dispatchWeight)}> Dispatch</button>
                        </td> */}
                      </tr>
                    );
                  })}
                </table>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <Norecord
          heading="Order-List"
          img={img}
          content="Sorry for disappointing you! We’re unable to find any relevant data"
        />
      )}
    </>
  );
};
export default CancelledOrders;

import { ApisauceInstance, create } from "apisauce";
import { DEFAULT_API_CONFIG } from "./api-config";
import { CONSTANT } from "../../utils/constant";
let api: ApisauceInstance;

const CreateAPI = async () => {
  // console.log(DEFAULT_API_CONFIG.url);
  return create({
    baseURL: DEFAULT_API_CONFIG.url,
    timeout: DEFAULT_API_CONFIG.timeout,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  });
};

export async function callAPI(
  url: any,
  isGet: boolean,
  request: any = {},
  header = {},
  isPut: boolean,
  isDelete: boolean
) {
  // console.log('payload',JSON.stringify(request));
  api = await CreateAPI();
  let response: any = {}
  if (!isPut && !isDelete) {
    response = isGet
      ? await api.get(url, request, { headers: header })
      : await api.post(url, request, { headers: header });
    return response;
  }
  else if (isDelete) {
    response = api.delete(url, request, { headers: header })
  }
  else {
    response = api.put(url, request, { headers: header });
  }
  return response;

}

// import { convertCompilerOptionsFromJson } from "typescript";
import store from "../../store/store";
import {callAPI} from "../config/api-core";
import {CartAPIMethods} from "../methods/cart-api-method";

const cartFetch = async (request:any): Promise<any> => {
  let login =  localStorage.getItem('token');
  let header = {
    Authorization: login && JSON.parse(login)?.access_token,
  };
  const response = await callAPI(
    // `${CartAPIMethods.cartList}?user=${request.user}`,
    CartAPIMethods.cartList,
    true,
    request,
    header,
    false,
    false
  );
  return response;
};

const postCartList = async (request: any): Promise<any> => {
  let login =  localStorage.getItem('token');
  let header = {
    Authorization: login && JSON.parse(login)?.access_token,
  };
  const response = await callAPI(
    CartAPIMethods.postCartList,
    false,
    request,
    header,
    false,
    false
  );

  return response;
};

const deletecartlist = async (request: any): Promise<any> => {
  let login =  localStorage.getItem('token');
  let header = {
    Authorization: login && JSON.parse(login)?.access_token,
  };
  console.log(request)
  const response = await callAPI(
  CartAPIMethods.deleteCartList,
    false,
    request,
    header,
    false, 
    true
  );

  return response;
};

const removeAllCart = async (request: any): Promise<any> => {
  let login =  localStorage.getItem('token');
  let header = {
    Authorization: login && JSON.parse(login)?.access_token,
  };
  console.log(request)
  const response = await callAPI(
  CartAPIMethods.removeAllCartList,
    false,
    request,
    header,
    false, 
    true
  );

  return response;
};

 const getCartListAPI = (request:any) => cartFetch(request);
 const postCartListAPI = (request: any) => postCartList(request);
 const deleteCartListAPI = (request: any) => deletecartlist(request);
 const removeAllCartListAPI = (request: any) => removeAllCart(request);
// eslint-disable-next-line import/no-anonymous-default-export
export default {
  getCartListAPI,
  postCartListAPI,
  deleteCartListAPI,
  removeAllCartListAPI
};

// import { convertCompilerOptionsFromJson } from "typescript";
import store from "../../store/store";
import {callAPI} from "../config/api-core";
import {WishlistAPIMethods} from "../methods/wishlist-api-method";

const wishlistFetch = async (request:any): Promise<any> => {
  let login =  localStorage.getItem('token');
  let header = {
    Authorization: login && JSON.parse(login)?.access_token,
  };
  const response = await callAPI(
    `${WishlistAPIMethods.wishlistList}?user=${request.user}`,
    true,
    request,
    header,
    false,
    false
  );
  return response;
};

const postwishlist = async (request: any): Promise<any> => {
  let login =  localStorage.getItem('token');
  let header = {
    Authorization: login && JSON.parse(login)?.access_token,
  };
  const response = await callAPI(
    `${WishlistAPIMethods.postwishlistList}?user=${request.user}&item_code=${request.item_code}`,
    true,
    {},
    header,
    false, 
    false
  );

  return response;
};

const deletewishlist = async (request: any): Promise<any> => {
  let login =  localStorage.getItem('token');
  let header = {
    Authorization: login && JSON.parse(login)?.access_token,
  };
  const response = await callAPI(
    `${WishlistAPIMethods.removewishlistList}?user=${request.user}&item_code=${request.item_code}`,
    false,
    {},
    header,
    false, 
    true
  );

  return response;
};

 const getWishlistAPI = (request:any) => wishlistFetch(request);
 const postWishlistAPI = (request: any) => postwishlist(request);
 const deleteWishlistAPI = (request: any) => deletewishlist(request);
// eslint-disable-next-line import/no-anonymous-default-export
export default {
  getWishlistAPI,
  postWishlistAPI,
  deleteWishlistAPI
};

import React, { useEffect, useState } from "react";
import BulkDropdownInput from "./BulkDropdown";
import useBulkListOrder from "../../pages/bulk-order/components/bulk-item-code-hook";

const BunchOrderDetials = ({
  formData,
  addBunchOrderRow,
  handleChangeArray,
  deleteBunchOrderRow,
  itemListBunch,
  errorMsg,
  totalEstimateWeight,
  fetchItemDetails,
  perInchWeight,
  perInchLengthSize,
}: // isBunchWeightDisabled,
any) => {
  const { refCodesList }: any = useBulkListOrder();

  const [inputValues, setInputValues] = useState<string[]>([]);
  const handleInputValueChange = (value: string, index: number) => {
    const newInputValues = [...inputValues];
    newInputValues[index] = value;
    setInputValues(newInputValues);
  };

  const [disableBunchSize, setDisableBunchSize] = useState<any>([]);
  const [readOnlyBunchWeight, setReadOnlyBunchWeight] = useState<any>([]);

  const handleBunchWeightChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    index: number
  ) => {
    const value = e.target.value;
    handleChangeArray(e, index, "bunch_weight");
    setDisableBunchSize((prevValues: any) => {
      const newValues = [...prevValues];
      newValues[index] = value;
      return newValues;
    });
  };

  const handleBunchSizeChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    index: number,
    subIndex: number
  ) => {
    const value = e.target.value;
    handleChangeArray(e, index, "size", subIndex);
    setReadOnlyBunchWeight((prevValues: any) => {
      const newValues = [...prevValues];
      newValues[index] = !!value; // Make bunch weight read-only if size has value
      return newValues;
    });
  };

  return (
    <>
      <div className="row mt-4 mb-2">
        <div className="col-md-9">
          <p className="fs-14">Bunch Orders Details</p>
        </div>
        <div className="col-md-3 d-flex justify-content-end">
          <button className="btn-save-bulk-order" onClick={addBunchOrderRow}>
            Add More
          </button>
        </div>
      </div>
      <div className="table-responsive">
        <table className="table table-bordered ">
          <thead>
            <tr>
              <th>
                Design Name<span className="text-danger">*</span>
              </th>
              <th>
                Description<span className="text-danger">*</span>
              </th>
              <th>Bunch Weight</th>
              <th>Bunch Length</th>
              <th>Per Inch Weight / Length</th>
              <th>Estimate Bunch Weight</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {formData?.bunchOrderDetails?.map((row: any, index: any) => (
              <tr key={index}>
                <td className="search-input-bulk-order">
                  <BulkDropdownInput
                    dropdownData={refCodesList?.map(
                      (ele: any) => ele?.reference_code
                    )}
                    inputValue={inputValues[index] || ""}
                    setInputValue={(value: string) =>
                      handleInputValueChange(value, index)
                    }
                    disabled={""}
                    name={`item_code${index}`}
                    onChange={(e: any) =>
                      handleChangeArray(
                        { target: { value: e } },
                        index,
                        "item_code"
                      )
                    }
                    keyBunch={"is_bunch"}
                    fetchItemDetails={fetchItemDetails}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    name={`description${index}`}
                    value={row?.description}
                    className="w-100 px-1"
                    onChange={(e) => handleChangeArray(e, index, "description")}
                  />
                </td>

                <td>
                  <input
                    type="text"
                    name={`bunch_weight${index}`}
                    value={!isNaN(row.bunch_weight) ? row.bunch_weight : ""}
                    className="w-100 px-1"
                    onChange={(e) => handleBunchWeightChange(e, index)}
                    // readOnly={!!readOnlyBunchWeight[index]}
                  />
                </td>

                {row?.qty &&
                  row?.qty?.map((item: any, subIndex: number) => (
                    <React.Fragment key={subIndex}>
                      <td>
                        <input
                          type="number"
                          name={`size${index}`}
                          value={item?.size}
                          className="w-100 input-type-number px-1"
                          onChange={(e) =>
                            handleBunchSizeChange(e, index, subIndex)
                          }
                          // disabled={!!disableBunchSize[index]}
                        />
                      </td>
                    </React.Fragment>
                  ))}

                <td>
                  <input
                    type="number"
                    name={`weight_per_unit${index}`}
                    value={row.weight_per_unit}
                    className="w-100 input-type-number px-1"
                    onChange={(e) =>
                      handleChangeArray(e, index, "weight_per_unit")
                    }
                    disabled
                  />
                </td>
                <td>
                  <input
                    type="number"
                    className="w-100 input-type-number px-1"
                    name={`estimate_bunch_weight${index}`}
                    value={
                      row?.bunch_weight === 0 || row?.bunch_weight === undefined
                        ? row?.bunch_weight
                        : row?.estimate_bunch_weight
                    }
                    onChange={(e) =>
                      handleChangeArray(e, index, "estimate_bunch_weight")
                    }
                    disabled
                  />
                </td>
                <td className="text-end">
                  <button
                    className="btn-delete-bulk-order "
                    onClick={() => deleteBunchOrderRow(index)}
                  >
                    Delete
                  </button>
                </td>
              </tr>
            ))}
            {errorMsg && (
              <tr>
                <td colSpan={7} className="w-100 text-danger text-center">
                  {errorMsg}
                </td>
              </tr>
            )}
          </tbody>
          {totalEstimateWeight !== 0 && (
            <tfoot>
              <tr className="text-start">
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td>Total : {totalEstimateWeight.toFixed(2)} gm</td>
              </tr>
            </tfoot>
          )}
        </table>
      </div>
    </>
  );
};

export default BunchOrderDetials;

import React, { useEffect, useState } from "react";
import { getRefCodesListBulkAPI } from "../../../services/api/bulk-order-api";

const useBulkListOrder = () => {
  const [customerName, setCustomerName] = useState<any>("");
  const [refCodesList, setRefCodesList] = useState<any>([]);

  const handleCustomerName: any = async () => {
    let refCodesList: any = await getRefCodesListBulkAPI(customerName);
    if (refCodesList?.status === 200) {
      setRefCodesList(refCodesList?.data?.data);
    } else {
      setRefCodesList([]);
    }
  };

  useEffect(() => {
    handleCustomerName();
  }, []);

  return {
    customerName,
    handleCustomerName,
    refCodesList,
  };
};

export default useBulkListOrder;

import React from "react";

const Footer=()=>{
  return(
    <footer className='footer'>
      <div className="container footer-container d-flex justify-content-center">
        <p className="copy">
          {/* Copyright 2022-2023 ARC. All rights reserved. */}
        </p>
      </div>
	</footer>
  )
}
export default Footer
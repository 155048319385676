import { Link, useParams } from "react-router-dom";
import { CategoryCard } from "../../components/cards/category-card";
import { useSubcategory } from "./components/subcategory-hooks";
import { Norecord } from "../../components/norecord";
import Breadcrumb from "../../components/breadcrumb";
const Category = () => {
  const { subcategories } = useSubcategory();
  let params = useParams(); //params get
  console.log(subcategories);
  return (
    <>
      <div className="breadcrumb-sec">
        <div className="container-lg">
          <div className="row">
            <div className="col-11">
              <Breadcrumb
                breadcrumbs={[
                  { to: "/categories", name: params.categoryId },
                  {
                    to: "/sub-category/" + params.categoryId,
                    name: "Subcategories",
                  },
                ]}
              />
            </div>
            {/* <div className="col-6 ">
            <Link to={"/all-product/" + params.categoryId}>View All Products</Link>
            </div> */}
          </div>
        </div>
      </div>

      <div className="text-right ">
        <Link
          to={"/all-product/" + params.categoryId + "/" + 1}
          className="view-all-link"
        >
          View All Products
        </Link>
      </div>

      <div className="container pt-5">
        <div className="row px-xl-5 pb-3">
          {subcategories.length > 0 ? (
            subcategories.map((subcategory: any, index: any) => {
              return (
                <CategoryCard
                  key={index + subcategory?.name}
                  name={subcategory.item_group_name}
                  image={subcategory.image}
                  col={subcategory.layout}
                  redirectUrl={
                    "/products/" +
                    params.categoryId +
                    "/" +
                    subcategory.item_group_name +
                    "?page=" +
                    1
                  }
                />
              );
            })
          ) : (
            <Norecord />
          )}
        </div>
      </div>
    </>
  );
};

export default Category; //category

// import {DEFAULT_API_CONFIG}  from "../services/config/api-config"

export enum CONSTANT {
  NO_INTERNET = "No Internet Connection Available!",
  ENTER_EMAIL = "Please enter email or mobile",
  ENTER_PASSWORD = "Please enter password",
  ENTER_FIRST_NAME = "Please enter first name",
  ENTER_LAST_NAME = "Please enter last name",
  ENTER_EMAIL_REGISTER = "Please enter email",
  ENTER_PHONE = "Please enter phone",
  ENTER_NEW_PASSWORD = "Please enter new password",
  ENTER_AMOUNT = "Please enter amount",
  NETWORK_ERROR = "Something went wrong. Please try again later!",
  LABEL_LOGOUT = "Logout",
  INVALID_USER = "Incorrect User or Password",
  // BASE_URL= "http://localhost:8001"
  // BASE_URL = " https://arc-catalog.frappe.cloud/"
  BASE_URL = "https://argold-catalog.8848digital.com",
}

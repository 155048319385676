import React, { FunctionComponent as Component } from "react";
import { Link } from "react-router-dom";
import {CardInterface} from './category-interface'
// import { CONSTANT } from '../../utils/constant';
import { DEFAULT_API_CONFIG } from "../../services/config/api-config";
import noimage from './../../assets/img/nia.jpg';

export const CategoryCard: Component<CardInterface> = (props: any) => {
  const { redirectUrl, name, image, col} = props;
  const layout:any = {'100% width' : 12, '50% width' : 6 , '33% width' : 4,'66% width' : 8};
  return (
    <div className={`${'col-lg-'+layout[col]+" "+'col-md-'+layout[col]+" "+'col-sm-'+layout[col]+" "}`}>
      <div className="cat-item d-flex flex-column">
        {/* <p className="text-right">15 Products</p> */}
        <Link
          to={(redirectUrl)?redirectUrl:null}
          className="cat-img overflow-hidden mb-3"
        >
          <img className="img-fluid d-block" src={(image)?DEFAULT_API_CONFIG.url + image : noimage} alt="" />
        </Link>
        
      </div>
      <div className="card-title">
          {/* <h5 className="font-weight-semi-bold pt-2 ">{name}</h5> */}
        </div> 
    </div>
  )  
}
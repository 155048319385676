import { Toast, ToastContainer } from "react-bootstrap"

interface props{
  setShow: any,
  show:any,
  content:any
}

const ToastNotification=({setShow, show, content}:props)=>{
  return(
    <ToastContainer className="p-3">
    <Toast
      onClose={() => setShow(false)}
      show={show}
      delay={3000}
      autohide
    >
      <Toast.Body>{content}</Toast.Body>
    </Toast>
  </ToastContainer>
  )
}
export default ToastNotification
import React, { useState } from "react";
import BulkDropdownInput from "./BulkDropdown";
import useBulkListOrder from "../../pages/bulk-order/components/bulk-item-code-hook";
const MarketOrderTable = ({
  formData,
  addMarketOrderRow,
  deleteAddMarketOrderRow,
  handleChangeArray,
  errorMsg,
}: any) => {
  const { refCodesList }: any = useBulkListOrder();
  const [inputValues, setInputValues] = useState<string[]>([]);
  const handleInputValueChange = (value: string, index: number) => {
    const newInputValues = [...inputValues];
    newInputValues[index] = value;
    setInputValues(newInputValues);
  };
  return (
    <>
      <div className="row mt-4 mb-2">
        <div className="col-md-9">
          <p className="fs-14">Market Order Details</p>
        </div>
        <div className="col-md-3 d-flex justify-content-end">
          <button className="btn-save-bulk-order" onClick={addMarketOrderRow}>
            Add More
          </button>
        </div>
      </div>
      <div className="table-responsive">
        <table className="table table-bordered">
          <thead>
            <tr>
              <th colSpan={1}>
                Design<span className="name-th-pd">Name</span>
                <span className="text-danger p-0 m-0">*</span>
              </th>
              <th>
                Description<span className="text-danger">*</span>
              </th>
              {[...Array(22)].map((_, i) => (
                <th key={i}>{`${14 + i} Inch Qty`}</th>
              ))}
              <th>1 Inch Qty</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {formData?.marketOrderDetails &&
              formData?.marketOrderDetails?.map((row: any, index: any) => (
                <tr>
                  <td colSpan={1} className="w-25 search-input-bulk-order ">
                    <BulkDropdownInput
                      dropdownData={refCodesList?.map(
                        (ele: any) => ele?.reference_code
                      )}
                      inputValue={inputValues[index] || ""}
                      setInputValue={(value: string) =>
                        handleInputValueChange(value, index)
                      }
                      disabled={""}
                      name={`item_code${index}`}
                      onChange={(e: any) =>
                        handleChangeArray(
                          { target: { value: e } },
                          index,
                          "item_code"
                        )
                      }
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      name={`description${index}`}
                      className="w-100 px-1"
                      value={row?.description}
                      onChange={(e) =>
                        handleChangeArray(e, index, "description")
                      }
                    />
                  </td>
                  {formData?.marketOrderDetails[index]?.qty.map(
                    (item: any, subIndex: number) => (
                      <td key={subIndex}>
                        <input
                          type="number"
                          name={`qty-${index}-${subIndex}`}
                          value={item.qty}
                          className={`input-type-number w-100 px-1`}
                          onChange={(e) =>
                            handleChangeArray(e, index, "qty", subIndex)
                          }
                        />
                      </td>
                    )
                  )}
                  <td className="text-end">
                    <button
                      className="btn-delete-bulk-order"
                      onClick={() => deleteAddMarketOrderRow(index)}
                    >
                      Delete
                    </button>
                  </td>
                </tr>
              ))}

            {errorMsg && (
              <tr>
                <td colSpan={25} className="w-100 text-danger text-center">
                  {errorMsg}
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </>
  );
};
export default MarketOrderTable;

import { Link } from "react-router-dom";
import React, { useState ,  useEffect } from "react";
import { useProducts } from "../pages/products/components/product-hooks";
import { useDispatch , useSelector } from "react-redux";
import { RootState } from "../store/root-reducer";
import { cartAPI } from "../store/screens/cart";

const Breadcrumb = (props:any) => {
  const { breadcrumbs,name } = props;
  const cart = useSelector((state: RootState) => state.carts);
  const [carts, setCarts] = useState<any>([]);
  console.log("testing breadcrumbs",breadcrumbs)
  const  { customerName }= useProducts();
  const dispatch = useDispatch();
  useEffect(() => {
    let user = localStorage.getItem("user");
    let users = user ? JSON.parse(user) : null;
    let params = {
      user: users,
    };
    dispatch(cartAPI(params));
  }, []);
  
  useEffect(()=>{
    setCarts(cart.cart);
  },[cart])
  console.log(name);
  const grandWeight = carts.total_grand_weight;
  console.log(cart.cart.total_grand_weight);

  return (
    <div className="container breadcrumb_main">
      <div className="row">
        <div className="col-6">
        <div id="breadcrumbs">
            <ul>
              {
                breadcrumbs.map((breadcrumb:any, index:any)=>(
                  <li key={index}>
                    <Link to={breadcrumb.to}>{breadcrumb.name}</Link>
                  </li>
                ))
              }
            </ul>
          </div>
        </div> 
        <div className="col-6">
          <div className="cust-info d-flex justify-content-center ">
          {/* <span>Cust Name: </span><span className="ps-2 pe-2">|</span> */}
          <span>Grand Total Weight: {grandWeight}</span>
          </div>
         
        </div>
      </div>
         
    </div>
   
  );
};
export default Breadcrumb;

import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useCart } from "../../../pages/cart/components/cart-hooks";
import { useWishlist } from "../../../pages/whishlist/components/wishlist-hooks";
import { RemoveAllItemsAPI } from "../../../store/screens/cart";
import { RootState } from "../../../store/root-reducer";
import { logoutUser } from "../../../store/screens/login";

export const useNavbar = () => {
  const dispatch = useDispatch();
  const cart = useSelector((state: RootState) => state.carts);
  const { wishlists } = useWishlist();
  const { carts } = useCart();
  const [wishlistCount, setWishlistCount] = useState(0);
  const [cartCount, setCartCount] = useState(0);

  useEffect(() => {
    // if(carts.data)
    // {
    //   console.log(carts.data.length)
    //   setCartCount(carts.data.length);
    // }
    // else
    // {
    //   console.log("got nothing")
    // }

    // setCartCount(carts.length);
    setWishlistCount(wishlists.length);
  }, [wishlists, wishlistCount, carts, cartCount]);

  useEffect(() => {
    if (carts.data) {
      console.log(carts.data.length);
      setCartCount(carts.total_items);
    } else {
      setCartCount(0);
      console.log("got nothing");
    }
  }, [carts.data]);

  const handleLogout = async () => {
    // let user = localStorage.getItem("user");
    // let users = user?JSON.parse(user) : null;
    // let param = {
    //   user: users,
    // };
    // dispatch(RemoveAllItemsAPI(param));
    dispatch(logoutUser());
  };

  return {
    wishlistCount,
    cartCount,
    handleLogout,
    setCartCount,
  };
};

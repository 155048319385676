import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import api from "../../services/api/navitems-api";
import { handleResponse } from "../../services/config/handle-response";
import { CONSTANT } from "../../utils/constant";

interface RepoNavitemsState {
  navitems: any;
  error: any;
}

const initialState: RepoNavitemsState = {
  navitems: [],
  error: "",
};

const navitemsScreen = createSlice({
  name: "navitems",
  initialState,
  reducers: {
    navitemsSuccess(state, action: PayloadAction<RepoNavitemsState>) {
      // localStorage.setItem('user', JSON.stringify(action.payload));
      state.navitems = action.payload;
      state.error = "";
    },
    navitemsFailed(state) {
      state.error = "Error or Occured";
    },
  },
});

const { navitemsSuccess, navitemsFailed } =  navitemsScreen.actions;

export const  navitemsAPI = (): any => async (dispatch: any) => {
  try {
    const response = await api.getNavitemsListAPI();
    const result = dispatch(handleResponse(response));
    if (result) {
      dispatch(navitemsSuccess(result));
    } else {
      dispatch(navitemsFailed());
    }
  } catch (err) {}
};

export default navitemsScreen.reducer;

import React, { useEffect, useState } from "react";
import {
  getDispatchedReportAPI,
  getDueDateReminderReportAPI,
  getInProcessReportAPI,
  getLateOrderReportAPI,
  getPendingReportAPI,
  getReviewReportAPI,
} from "../../../../services/api/report-api";

const useReportList = () => {
  const currentURL = window.location.pathname;

  const [reportListingData, setReportListingData] = useState<any>([]);
  const [calculationRowData, setCalculationRowData] = useState<any>({
    qty: "",
    total_wt: "",
    total_net_wt: "",
  });

  const getReportListingApiRes: any = (response: any) => {
    if (response?.data?.message?.msg === "success") {
      let data: any = response.data.message.data;
      setReportListingData(data);
      calculateTotalAmountOfColumns(data);
    } else {
      setReportListingData([]);
    }
  };

  const calculateTotalAmountOfColumns = (data: any) => {
    let qtyTotal = 0;
    let totalWt = 0;
    let totalNetWt = 0;
    data.forEach((item: any) => {
      qtyTotal += item.qty;
      totalWt += item.total_weight;
      totalNetWt += item.total_net_weight;
    });
    setCalculationRowData({
      qty: qtyTotal,
      total_wt: totalWt,
      total_net_wt: totalNetWt,
    });
  };

  const getReportListingDataFun: any = async () => {
    let getReportListingData: any;
    switch (currentURL) {
      case "/reports/pending-orders-report":
        getReportListingData = await getPendingReportAPI();
        if (getReportListingData?.status === 200) {
          getReportListingApiRes(getReportListingData);
        } else {
          getReportListingData(null);
        }
        break;

      case "/reports/in-process-orders-report":
        getReportListingData = await getInProcessReportAPI();
        if (getReportListingData?.status === 200) {
          getReportListingApiRes(getReportListingData);
        } else {
          getReportListingData(null);
        }
        break;

      case "/reports/dispatched-orders-report":
        getReportListingData = await getDispatchedReportAPI();
        if (getReportListingData?.status === 200) {
          getReportListingApiRes(getReportListingData);
        } else {
          getReportListingData(null);
        }
        break;

      case "/reports/review-report":
        getReportListingData = await getReviewReportAPI();
        if (getReportListingData?.status === 200) {
          getReportListingApiRes(getReportListingData);
        } else {
          getReportListingData(null);
        }
        break;

      case "/reports/due-date-reminder-report":
        getReportListingData = await getDueDateReminderReportAPI();
        if (getReportListingData?.status === 200) {
          getReportListingApiRes(getReportListingData);
        } else {
          getReportListingData(null);
        }
        break;

      case "/reports/late-orders-report":
        getReportListingData = await getLateOrderReportAPI();
        if (getReportListingData?.status === 200) {
          getReportListingApiRes(getReportListingData);
        } else {
          getReportListingData(null);
        }
        break;

      default:
        break;
    }
  };

  useEffect(() => {
    getReportListingDataFun();
  }, [currentURL]);

  return { reportListingData, calculationRowData };
};

export default useReportList;

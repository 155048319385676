import "../../assets/css/bulk-order.css";
import MasterBulkOrder from "../../components/bulkorder/MasterBulkOrder";

const BulkOrderList = () => {
  return (
    <>
      <MasterBulkOrder />
    </>
  );
};

export default BulkOrderList;

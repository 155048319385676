import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useCart } from "../../pages/cart/components/cart-hooks";
import { RootState } from "../../store/root-reducer";
import { cartAPI, RemoveAllItemsAPI } from "../../store/screens/cart";
import { puritysAPI, selectedPurityReducer } from "../../store/screens/purity";
import { useNavbar } from "../navbar/components/navbar-hooks";

export const usePurity = () => {
  const [purity, setPurity] = useState([]);
  const puritys = useSelector((state: RootState) => state.puritys);
  const dispatch = useDispatch();
  const { cartCount } = useNavbar();
  const [defaultPurity, setDefaultPurity] = useState("22KT");
  const [selectedPurity, setSelectedPurity] = useState("purity");
  const [isOpen, setIsOpen] = useState(false);

  const { carts } = useCart();

  useEffect(() => {
    dispatch(puritysAPI());
  }, []);

  useEffect(() => {
    setPurity(puritys.puritys);
  }, [puritys]);

  useEffect(() => {
    if (carts.length > 0) {
      console.log("purity data", carts[0].purity);
      setDefaultPurity(carts[0].purity);
      localStorage.setItem("localPurity", JSON.stringify(carts[0].purity));
    } else if (
      carts.length === 0 &&
      localStorage.getItem("localPurity") == null
    ) {
      localStorage.setItem("localPurity", defaultPurity);
      console.log("2", defaultPurity);
    } else {
      let local: any = localStorage.getItem("localPurity");
      setDefaultPurity(local);
    }
  }, [carts.length]);

  const clearCart = async (e: any) => {
    e.preventDefault();
    let user = localStorage.getItem("user");
    let users = user ? JSON.parse(user) : null;
    let param = {
      user: users,
    };
    await dispatch(RemoveAllItemsAPI(param));
    await dispatch(cartAPI(param));
    await setDefaultPurity(selectedPurity);
    await localStorage.setItem("localPurity", JSON.stringify(selectedPurity));
    hideModal();
  };
  const handlePurity = (e: any, name: any) => {
    e.preventDefault();
    setSelectedPurity(name);
    dispatch(selectedPurityReducer(name));
    if (cartCount > 0) {
      setIsOpen(true);
    } else {
      setDefaultPurity(name);
      localStorage.setItem("localPurity", name);
    }
  };

  const hideModal = () => {
    setIsOpen(false);
  };

  return {
    purity,
    defaultPurity,
    isOpen,
    clearCart,
    handlePurity,
    hideModal,
    setDefaultPurity,
    cartCount,
    setIsOpen,
  };
};

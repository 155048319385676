// import { convertCompilerOptionsFromJson } from "typescript";
import  store  from "../../store/store";
import { callAPI } from "../config/api-core";
import { CategoriesAPIMethods } from "../methods/categories-api-method";

const categoryFetch = async (type:any): Promise<any> => {
  let login = localStorage.getItem("token");
  let header = {
    Authorization: login && JSON.parse(login)?.access_token,
  };
  const response = await callAPI(
    `${CategoriesAPIMethods.categoriesList}?filters={"group_level":"1","parent_item_group":"${type}"}&fields=["name","layout","image"]&order_by=order_sequence`,
    true,
    {},
    header,
    false,
    false
  );
  return response;
};

const getCategoriesListAPI = (type:any) => categoryFetch(type);
// eslint-disable-next-line import/no-anonymous-default-export
export default {
  getCategoriesListAPI,
};

import axios from "axios";
import { DEFAULT_API_CONFIG } from "../config/api-config";

export const getRefCodesListBulkAPI: any = async (customer_name: any) => {
  let response;
  let login: any = localStorage.getItem("token");
  const config = {
    headers: {
      Authorization: login && JSON.parse(login)?.access_token,
    },
  };
  const url = `${DEFAULT_API_CONFIG?.url}/api/resource/Customer%20Item%20Reference%20Code?fields=[%22name%22%2C%22item_code%22%2C%22customer_name%22%2C%22reference_code%22]&customer_name=${customer_name}&limit=none`;

  await axios
    .get(`${url}`, config)
    .then((res: any) => {
      response = res;
    })
    .catch((err: any) => {
      response = err;
    });
  return response;
};

const createNewQuickOrderRecord: any = async (values: any) => {
  let response: any;

  let login = localStorage.getItem("token");
  const url = `${DEFAULT_API_CONFIG?.url}api/method/digitalcatalog_api_erpnext.api.quotation.create_sales_quotation`;

  const config = {
    headers: {
      Authorization: login && JSON.parse(login)?.access_token,
    },
  };

  await axios
    .post(`${url}`, values, config)
    .then((res: any) => {
      response = res;
    })
    .catch((err: any) => {
      response = err;
    });

  return response;
};

export default createNewQuickOrderRecord;

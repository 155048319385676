import axios from "axios";
import { DEFAULT_API_CONFIG } from "../config/api-config";

const GetSearchData = async (search_value: string) => {
  let response: any;
  
  let login = localStorage.getItem("token");
  const url: string = `${DEFAULT_API_CONFIG.url}/api/method/digitalcatalog_api_erpnext.api.product_list.search_product`;
  const params: string = `search_value=${search_value}`;
  const config = {
    headers: {
      Authorization: login && JSON.parse(login)?.access_token
    }
  };
  await axios
    .get(`${url}?${params}`, config)
    .then((res: any) => {
      response = res;
    })
    .catch((err: any) => {
      console.log(err);
      response = err;
    });
  return response;
};

export default GetSearchData;

import useReviewReportList from "./components/review-report-list-hook";
import "../../../assets/css/order-list.css";
import "../../../assets/css/review-report-list.css";
import img from "../../../assets/img/order-list.png";
import { Norecord } from "../../../components/norecord";
import { useNavigate } from "react-router-dom";

const ReviewReportList = () => {
  const navigate = useNavigate();
  // const reviewListData = useReviewReportList();
  // console.log("review list data in component", reviewListData);
  return (
    <>
      {/* {reviewListData?.length > 0 ? (
        <div className="container-lg pt-5 mt-5">
          <div className="order-list-cont">
            <div className="row pb-3">
              <div className="order-heading text-center">
                <h2>Review Report</h2>
              </div>
              <div className="container ">
                <div className="d-flex justify-content-center align-items-center flex-wrap flex-container">
                  {reviewListData?.map((review: any) => {
                    return (
                      <div className="d-flex mt-3 me-3 flex-item">
                        <div className="padding-item ">
                          <h3>{review?.sales_order_no}</h3>
                        </div>
                        <div className="padding-item  customer-name-div">
                          <h3>{review?.customer_name}</h3>
                        </div>
                        <div className="padding-item  ">
                          <h3>{review?.order_date}</h3>
                        </div>

                        <div className="check-review-btn-div ">
                          <button
                            className="check-review-btn"
                            onClick={() => {
                              navigate(
                                `/reports/review-report/${review?.sales_order_no}`
                              );
                            }}
                          >
                            View Reviews
                          </button>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="mt-5">
          <Norecord
            heading="Review Report"
            img={img}
            content="Sorry for disappointing you! We’re unable to find any relevant data"
          />
        </div>
      )} */}
    </>
  );
};

export default ReviewReportList;

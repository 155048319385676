import React from "react";
import BulkOrderBreadCrumb from "./BulkOrderBreadCrumb";
import BulkOrderInputFields from "./BulkOrderInputFields";
import CustomeMarketOrder from "./CustomeMarketOrder";
import BunchOrderDetials from "./BunchOrderDetials";
import { useBulkOrder } from "../../pages/bulk-order/components/bulk-order-hook";
import MarketOrderTable from "./MarketOrderTable";
import ToastNotification from "../toast-notification";

const MasterBulkOrder = () => {
  const {
    formData,
    handleChange,
    handleChangeArray,
    addMarketOrderRow,
    deleteAddMarketOrderRow,
    addCustomMarketOrderRow,
    addBunchOrderRow,
    deleteBunchOrderRow,
    deleteCustomMarketOrderRow,
    handleSubmit,
    itemList,
    itemListBunch,
    itemListCustom,
    handleChangeArrayCustom,
    handleChangeArrayBunch,
    purityList,
    notificationMessage,
    bulkOrderNotification,
    setBulkOrderNotification,
    errorMsgMarket,
    errorMsgCustom,
    errorMsgBunch,
    calculateEstimateBunchWeight,
    totalEstimateWeight,
    fetchItemDetails,
    perInchWeight,
    perInchLengthSize,
    isBunchWeightDisabled,
  }: any = useBulkOrder();

  return (
    <>
      <ToastNotification
        setShow={setBulkOrderNotification}
        show={bulkOrderNotification}
        content={notificationMessage}
      />
      <BulkOrderBreadCrumb />

      <div className="container-lg mb-5 pb-5">
        <div className="bulk-list-cont">
          <div className="row mt-4">
            <div className=" text-center">
              <h1>Bulk Order</h1>
            </div>
            <div className="">
              <BulkOrderInputFields
                formData={formData}
                handleChange={handleChange}
                purityList={purityList}
              />
              <MarketOrderTable
                formData={formData}
                addMarketOrderRow={addMarketOrderRow}
                deleteAddMarketOrderRow={deleteAddMarketOrderRow}
                handleChangeArray={handleChangeArray}
                itemList={itemList}
                errorMsg={errorMsgMarket}
              />
              <CustomeMarketOrder
                formData={formData}
                addCustomMarketOrderRow={addCustomMarketOrderRow}
                handleChangeArray={handleChangeArrayCustom}
                deleteCustomMarketOrderRow={deleteCustomMarketOrderRow}
                itemListCustom={itemListCustom}
                errorMsg={errorMsgCustom}
              />
              <BunchOrderDetials
                formData={formData}
                addBunchOrderRow={addBunchOrderRow}
                handleChangeArray={handleChangeArrayBunch}
                itemList={itemListBunch}
                deleteBunchOrderRow={deleteBunchOrderRow}
                itemListBunch={itemListBunch}
                errorMsg={errorMsgBunch}
                totalEstimateWeight={totalEstimateWeight}
                fetchItemDetails={fetchItemDetails}
                perInchWeight={perInchWeight}
                perInchLengthSize={perInchLengthSize}
                isBunchWeightDisabled={isBunchWeightDisabled}
              />
            </div>
          </div>

          <div className="row">
            <div className="col-md-6">
              <button
                onClick={handleSubmit}
                className="btn-save-bulk-order save-btn"
              >
                Save
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MasterBulkOrder;

import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { RootState } from "../../../store/root-reducer";
import { allproductsAPI } from "../../../store/screens/products";
import { useProducts } from "../../products/components/product-hooks";

export const useAllProducts = () => {
  const dispatch = useDispatch();
  const param = useParams();
  const { curretPage, checked } = useProducts();
  const [allProducts, setAllProducts] = useState([]);
  const [totalCount, setTotalRecord] = useState(1);
  const allproducts = useSelector(
    (state: RootState) => state.products.allproducts
  );
  console.log(allproducts);

  useEffect(() => {
    dispatch(allproductsAPI(param.categoryId, "", "", 0));
  }, [dispatch, param.categoryId]);

  useEffect(() => {
    setTotalRecord(allproducts.total_count);
    setAllProducts(allproducts.product);
  }, [allproducts.product, curretPage, checked, allproducts.total_count]);

  return {
    allProducts,
    totalCount,
    allproducts,
  };
};

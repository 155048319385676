import { Navigate } from 'react-router-dom';
import Navbar from '../components/navbar';
import Footer from '../components/footer';
import { PFRouteProps } from "./protectedRouteProps.props";
import { useSelector } from 'react-redux';
import { RootState } from '../store/root-reducer';

export const ProtectedRoute: React.FC<PFRouteProps> = ({children}) => {
  const login = useSelector((state: RootState) => state.login);
  let user = false;
  let userFromLocalStorage  =  localStorage.getItem('token');
  // console.log(userFromLocalStorage)
  if(login.user.access_token){
    user = true;
  }else if(userFromLocalStorage && JSON.parse(userFromLocalStorage).access_token){
    user = true;
  }
  if (!user) {
    return <Navigate to="/" replace />;
  }
  return (
    <>
      <Navbar/>
        {children}
      <Footer />
    </>
  )
};
export default ProtectedRoute;
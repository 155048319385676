import useReviewReportDetail from "./components/review-report-detail-hook";
import "../../../assets/css/review-report-detail.css";
import { Link } from "react-router-dom";

const ReviewReportDetail = () => {
  const reviewDetailData = useReviewReportDetail();
  // console.log("review detail data in component", reviewDetailData);

  return (
    <>
      {reviewDetailData?.hasOwnProperty("sales_order_no") && (
        <div>
          <div className="container-lg main">
            <div className="order-heading text-center">
              <h2>Review Report Detail</h2>
            </div>

            <div
              className="container"
              style={{
                border: "1px solid #ECF0F1",
                marginTop: "5px",
              }}
            >
              <div className="row">
                <div className="col-12">
                  <h4 className="m-3">
                    Sales Order ID : {reviewDetailData?.sales_order_no}
                  </h4>
                  <h4 className="m-3">
                    Customer Name : {reviewDetailData?.customer_name}
                  </h4>
                  <h4 className="m-3">
                    Order Date : {reviewDetailData?.order_date}
                  </h4>

                  <Link
                    to={`/order-detail/${reviewDetailData?.sales_order_no}?status=Completed`}
                    className="m-3"
                    style={{ color: "blue" }}
                  >
                    <b>
                      Click here to view more details of{" "}
                      {reviewDetailData?.sales_order_no}
                    </b>
                  </Link>
                </div>
                <div className="container row">
                  <div className="col-12">
                    <div className="ms-2 mt-3 mb-3">
                      <h2>Items Review :</h2>
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="row black border" style={{}}>
                      <div className="col-4 border-bottom border-top p-0 col-bg">
                        <div className="border-end" style={{marginLeft:"25px"}}>
                          Design Name
                        </div>
                      </div>
                      <div className="col-8 border-bottom border-top p-0 col-bg">
                        <div className="border-end" style={{marginLeft:"25px"}}>Review</div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="row">
                    {reviewDetailData?.items?.map((item: any) => {
                      return (
                        <>
                          <div className="col-4 p-2">
                            <h6 className="ms-3" style={{ width: "200px" }}>
                              {item?.design}
                            </h6>
                          </div>
                          <div className="col-8 p-2">
                            <h6 className="ms-3">{item?.review}</h6>
                          </div>

                          <hr/>
                        </>
                      );
                    })}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ReviewReportDetail;
